.MuiTabs-indicator {
  background: #90caf9;
}
.MuiTabs-flexContainer {
  button {
    width: 50%;
    font-size: 14px;
    font-weight: bold;
  }
}
.MuiTabs-root {
  border-bottom: solid 1px grey !important;
}
