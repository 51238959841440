.expected-well-counts {
  align-self: flex-start;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;

  .count-container {
    display: flex;
    align-items: center;
    gap: 5px;

    .MuiTypography-root {
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.8px;
      color: rgba(255, 255, 255, 0.7);
    }

    .well-count-value {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.8px;

      .MuiCircularProgress-root {
        margin-left: 4px;
      }
    }
  }
}
