/* old $page-width:88.5%;*/
$page-width: 96%;

/* Font Colors */
$arlas-cs-font-1: #00aaab;
$arlas-cs-font-2: #ffffff;
$arlas-cs-font-3: rgba(255, 255, 255, 0.7);
$arlas-cs-mandatory: #f9a01f;
$arlas-font-2: #ffffff;

/* Background Colors */
$arlas-cs-bg-1: #00aaab;
$arlas-cs-bg-1-dark: #007778;
$arlas-cs-bg-2: #ffffff;
$arlas-cs-bg-3: rgba(0, 0, 0, 0.9);
$arlas-cs-bg-4: rgba(0, 0, 0, 0.8);
$arlas-cs-bg-5: #aa0071;
$arlas-cs-bg-6: #676767;
$arlas-cs-bg-even: rgba(78, 78, 78, 0.1);
$arlas-cs-bg-header: #333333;
$arlas-cs-bg-body: #363636;
$arlas-cs-loader: #00aaab;
$arlas-cs-loader-path: rgba(0, 170, 171, 0.2);
$arlas-cs-bg-maptool: rgba(0, 0, 0, 0.5);
$arlas-cs-bg-graphsection: #2a2a2a;

/* Border Colors */
$arlas-cs-bdr-1: #00aaab;
$arlas-cs-bdr-1-dark: #007778;
$arlas-cs-bdr-2: #ffffff;
$arlas-cs-bdr-3: rgba(56, 13, 13, 0.3);
$arlas-cs-bdr-4: rgba(238, 238, 238, 0.1);
$arlas-cs-bdr-5: #4e4e4e;
$arlas-cs-bdr-6: rgba(255, 255, 255, 0.08);
$arlas-cs-bdr-7: #363636;
$arlas-cs-bdr-8: #555555;
$arlas-cs-bdr-9: #656565;
$arlas-cs-bdr-well-active: rgba(0, 170, 171, 0.6);
$arlas-cs-bdr-well-deactive: rgba(112, 112, 112, 0.2);
$arlas-cs-bdr-input: rgba(255, 255, 255, 0.5);
$arlas-cs-bdr-footer: rgba(0, 0, 0, 0.5);
$arlas-cs-bdr-mandatory: #f9a01f;
$arlas-cs-bdr-maptool: rgba(255, 255, 255, 0.2);
$arlas-bdr-3: rgba(255, 255, 255, 0.3);

/* Box Shadow */
$arlas-cs-shadow-1: rgba(0, 0, 0, 0.4);
$arlas-cs-shadow-2: rgba(0, 0, 0, 0.16);
$arlas-cs-shadow-3: rgba(0, 0, 0, 1);

/* Scroll Bar */
$arlas-cs-scrollbar-width: 14px;
$arlas-cs-scrollbar-track: #2e2e2e;
$arlas-cs-scrollbar-thumb: #ffffff26;
$arlas-cs-scrollbar-thumb-hover: #ffffff26;

/* Map Tool Tip */
$arlas-cs-tt-base: #fff;
$arlas-cs-tt-primary: #00aaab;
$arlas-cs-tt-primarydark: #007778;
$arlas-bg-option: #333333;
$arlas-bdr-option: #676767;

$immersive-panel-shadow: rgba(38, 38, 39, 0.92);
