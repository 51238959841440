.tgs-snackbar {
  &.MuiSnackbar-root {
    position: absolute;
    bottom: 80px;

    .MuiSnackbarContent-root {
      background-color: #121212;
      color: #fff;
    }
  }
}

.tgs-snackbar-custom-node {
  .MuiSnackbarContent-root {
    background-color: transparent;
    background-image: none;
  }

  .custom-alert {
    color: #ffffff;

    .MuiAlert-icon {
      color: #ffffff;
    }
  }
}
