@import "partials/variables";
@import url(https://openlayers.org/theme/ol.css);

// SagaMap
.saga-map-container {
  position: relative;
  height: 100%;
}

.ol-overlaycontainer-stopevent {
  z-index: unset !important;
}

.map-container {
  background-color: #222327;
  display: flex;
  width: 100%;
  height: 100%;
  transition: padding-left 0.25s ease;
  padding-left: 0px;

  &.mini-map-padding- {
    &pane-open {
      .mini-map {
        right: 484px;
      }
    }
  }

  .mini-map {
    position: absolute;
    right: 10px;
    bottom: 85px;
    transition: all 0.25s ease;
    z-index: 1;

    &:not(.ol-collapsed) {
      overflow: hidden;
      border-radius: 5px;
      border: 1px solid #878787;
      height: 120px;
    }

    & .ol-overviewmap-map {
      border: none;
      width: 220px;
      height: 120px;
    }

    & .ol-overviewmap-box {
      border-radius: 2.5px;
      border: 2px solid #3fa7ff;
    }

    &:not(.ol-collapsed) button {
      position: absolute;
      bottom: auto;
      left: auto;
      right: 1px;
      top: 1px;
    }

    & .ol-rotate {
      top: 170px;
      right: 0;
    }

    button {
      display: none;
    }
  }
}

.ol-scale-bar {
  left: 42px;
  bottom: 30px;

  .ol-scale-singlebar {
    border: 1px solid #000;

    &-odd {
      background-color: #fff;
    }

    &-even {
      background-color: #000;
    }
  }

  .ol-scale-step-marker {
    background-color: #000;
  }

  .ol-scale-step-text {
    color: #fff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
  }
}

.wells-card-container {
  width: 0;
  position: absolute;
  z-index: 2;
  .MuiPaper-root {
    box-shadow: -1px 2px 6px -1px #7b7b7b;
  }
}

// ActionControl
.action-control-wrapper {
  bottom: 20px;

  .map-tools-container {
    position: absolute;
    right: 265px;
    bottom: 10px;
    margin-bottom: 0;

    &.open {
      right: 764px;
    }

    .collapsed-grid {
      bottom: 48px;
    }
  }
}

main {
  .arlas-cs-map-section {
    position: relative;
    max-width: 100vw;
    height: 100%;
    overflow: hidden;
    // @include set-height(calc(100vh - 41px));
  }
}

.modules-menu-container {
  position: relative;
  top: 8px;
  right: 8px;
}

.hover-card-container {
  z-index: 1300;
  box-shadow: 0px 0px 10px 0px #00000040;
  min-height: 261px;
  width: 350px;
  background: #2e2e2e;
  border-radius: 5px;

  &.distinction-menu {
    width: unset;
    background: #121212;
    min-height: unset;

    .MuiMenuItem-root {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 12px 8px 10px;
      gap: 8px;

      .distinction-menu-icon {
        align-self: start;
        margin-top: 0px;
      }

      .distinction-menu-labels {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 2px;

        .distinction-menu-uwi {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0.17px;
          text-align: left;
          color: #ffffffb2;
        }

        .distinction-menu-well-name-number {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          letter-spacing: 0.17px;
          text-align: left;
          color: #ffffff;
        }
      }
    }
  }

  &[data-popper-placement="bottom-start"] .hover-card-arrow {
    top: -6px;
    &::before {
      content: "";
      margin: auto;
      display: block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0px 7px 7px;
      border-color: transparent transparent #1e1e1e;
    }

    &.distinction-menu {
      &::before {
        border-color: transparent transparent #000000;
      }
    }
  }

  &[data-popper-placement="top-start"] .hover-card-arrow {
    bottom: -7px;
    &::after {
      content: "";
      margin: auto;
      display: block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0px 7px 7px;
      transform: rotate(180deg);
      border-color: transparent transparent #2e2e2e;
    }

    &.distinction-menu {
      &::after {
        border-color: transparent transparent #000000;
      }
    }
  }

  &[data-popper-placement="left"] .hover-card-arrow {
    top: 55px !important;
    right: -10px;
    &::before {
      content: "";
      margin: auto;
      display: block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0px 7px 7px;
      transform: rotate(90deg);
      border-color: transparent transparent #2e2e2e;
    }

    &.distinction-menu {
      &::before {
        border-color: transparent transparent #000000;
      }
    }
  }

  &[data-popper-placement="left-start"] .hover-card-arrow {
    top: 0px !important;
    right: -10px;
    &::before {
      content: "";
      margin: auto;
      display: block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0px 7px 7px;
      transform: rotate(90deg);
      border-color: transparent transparent #2e2e2e;
    }

    &.distinction-menu {
      &::before {
        border-color: transparent transparent #000000;
      }
    }
  }

  &[data-popper-placement="right-start"] .hover-card-arrow {
    top: 0px !important;
    left: -10px;
    &::before {
      content: "";
      margin: auto;
      display: block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0px 7px 7px;
      transform: rotate(-90deg);
      border-color: transparent transparent #2e2e2e;
    }

    &.distinction-menu {
      &::before {
        border-color: transparent transparent #000000;
      }
    }
  }
}

.hover-card-header-container {
  background-color: #1e1e1e;
  padding: 10px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  & .hover-card-uwi-status {
    display: flex;
    justify-content: space-between;

    & div:first-child {
      & span {
        font-size: 12px;
        color: #ffffffb2;
        letter-spacing: 1px;
      }
    }

    .hover-card-pill-container {
      display: flex;
      flex-direction: row;
      text-align: right;
      gap: 4px;

      .hover-card-pill {
        color: #ffffff;
        font-size: 12px;
        letter-spacing: 0.4px;
        background-color: #ffffff1a;
        padding: 3px 10px;
        border-radius: 15px;
        white-space: nowrap;

        &.with-icon {
          display: flex;
          flex-direction: row;
          align-items: center;

          span.hover-card-pill-icon {
            margin-top: 0px;
            height: 10px;
            width: 10px;
            margin-right: 3px;
          }
        }
      }
    }
  }

  & .hover-card-well-name {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
  }
}

.well-label-other-info-container {
  & .well-label-other-info {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & .well-label-col {
      padding: 10px;

      & .well-label-grouped-info {
        display: grid;
        grid-template-columns: 25px 130px;
        min-height: 50px;

        & .well-info-icon {
          text-align: right;
          margin-right: 10px;

          & svg {
            vertical-align: text-top;
          }
        }

        & .well-info-label {
          font-size: 12px;
          letter-spacing: 0.15px;
          color: #9c9c9c;
        }
        & .well-info-value {
          font-size: 14px;
          letter-spacing: 0.15px;
          padding-left: 2px;

          &.tgs-operator {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.hover-card-footer-container {
  border-top: 1px solid #ffffff1a;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  button.tgsccl-button {
    // color: rgb(0, 0, 0);
    .more-info-text {
      font-weight: bold;
      font-size: 12px;
    }
    &.disabled {
      cursor: default;
      pointer-events: none;
    }
  }
}

.alert-box {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 200;
  max-width: 500px;

  div.tgsccl-alert img.tgsccl-alert-img-icon {
    width: 40px;
  }
}
