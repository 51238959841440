.styled-scrollbars {
  scrollbar-color: $arlas-cs-scrollbar-thumb $arlas-cs-scrollbar-track;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: transparent;
    height: $arlas-cs-scrollbar-width;
    width: $arlas-cs-scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background: $arlas-cs-scrollbar-track;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $arlas-cs-scrollbar-thumb;
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $arlas-cs-scrollbar-thumb-hover;
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 4px;
  }
}

.primary-button {
  height: 30px;
  background-color: #fff !important;
  color: #f9a01b !important;
  border: 1px #f9a01b solid !important;
  margin: 6px !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 5px !important;

  &:hover {
    background-color: #fae9cf !important;
  }

  &.active {
    color: #fff !important;
    background-color: #f9a01b !important;

    &:hover {
      background-color: #e79110 !important;
    }
  }
}

.pointer {
  cursor: pointer;
}

.text-custom-grey {
  color: rgba(0, 0, 0, 0.87);
}

.relative {
  position: relative;
}

.custom-input {
  background-color: #fff;
  font-size: 14px;
  padding: 15px 15px;
  outline: none;
  border: 1px #c4c4c4 solid;
  border-radius: 5px !important;
  width: 100%;

  &.has-left-icon {
    padding-left: 40px;
  }

  &.has-right-icon {
    padding-right: 40px;
  }
}

.input-icon {
  &-left {
    position: absolute;
    top: 15px;
    left: 10px;
  }

  &-right {
    position: absolute;
    top: 15px;
    right: 10px;
  }
}

.blue-circle-icon {
  background-color: #0070ff;
  border-radius: 50%;
  width: 19px;
  height: 19px;
}

.coral-reef {
  background-color: #680054;
  border-radius: 50%;
  width: 19px;
  height: 19px;
}

.critical-habitat {
  background-color: #c06b15;
  width: 19px;
  height: 19px;
}

.environ-zone {
  background-color: #747500;
  width: 19px;
  height: 19px;
}
.fishing {
  background-color: #f9a01b;
  width: 19px;
  height: 19px;
}
.marine-protected {
  background-color: #00696a;
  width: 19px;
  height: 19px;
}
.special-protection {
  background-color: #cbc911;
  width: 19px;
  height: 19px;
}

.uxo {
  background-color: #800000;
  width: 19px;
  height: 19px;
}

.ong-facility {
  background-color: #680054;
  width: 19px;
  height: 19px;
}

.military-zone {
  background-color: #ff0000;
  width: 19px;
  height: 19px;
}

.ong-pipeline {
  border-left: 32px solid #ffbee8;
  height: 5px;
  left: 5%;
  top: 10;
}

.shipping-lane {
  background-color: #003660;
  width: 19px;
  height: 19px;
}

.subsea-cable {
  border-left: 32px solid #808285;
  height: 5px;
  left: 5%;
  top: 10;
}

.black-circle-icon {
  background-color: #050303;
  border-radius: 50%;
  width: 19px;
  height: 19px;
}

.blue-white-circle-icon {
  // background-color: #050303;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background: white;
  border: 2px solid #0070ff;
}

.offshore-leases {
  background-color: #d8f9c4;
  width: 15px;
  height: 15px;
  outline-style: solid;
  outline-color: #0070ff;
  margin-right: 20%;
}

.offshore-cables {
  border-left: 32px solid #0070ff;
  height: 5px;
  left: 5%;
  top: 10;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 240px;
  white-space: nowrap;
  // overflow: hidden;
  // text-overflow: clip;
}

.ol-popup-title {
  font-size: 15px;
  white-space: normal;
}

table,
th,
tr,
pre {
  color: #003660;
  font-family: "Roboto Medium", sans-serif;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
}

.popup-content strong {
  font-weight: bold;
  font-size: larger;
}

.transparency-label {
  font-size: 12px;
  display: "flex";
}

.fs {
  &-10 {
    font-size: 10px !important;
  }

  &-11 {
    font-size: 11px !important;
  }

  &-12 {
    font-size: 12px !important;
  }

  &-13 {
    font-size: 13px !important;
  }

  &-14 {
    font-size: 14px !important;
  }

  &-15 {
    font-size: 15px !important;
  }

  &-16 {
    font-size: 16px !important;
  }

  &-17 {
    font-size: 17px !important;
  }

  &-18 {
    font-size: 18px !important;
  }

  &-19 {
    font-size: 19px !important;
  }

  &-20 {
    font-size: 20px !important;
  }

  &-21 {
    font-size: 21px !important;
  }

  &-22 {
    font-size: 22px !important;
  }

  &-23 {
    font-size: 23px !important;
  }

  &-24 {
    font-size: 24px !important;
  }
}

.modal-back-button {
  display: flex;
  font-family: "Roboto Medium", sans-serif;
  color: #4d4d4d;

  span {
    line-height: 27px;
    font-size: 18px;
  }
}

.grey-bottom-border {
  border-bottom-width: 1px;
  border-bottom-color: #e0e0e0;
  border-bottom-style: solid;
}

.grey-right-border {
  border-right-width: 1px;
  border-right-color: #e0e0e0;
  border-right-style: solid;
}

.mgn {
  &-4 {
    margin: 4px !important;
  }
  &-8 {
    margin: 8px !important;
  }
  &-12 {
    margin: 12px !important;
  }
  &-16 {
    margin: 16px !important;
  }

  &Y {
    &-4 {
      margin-top: 4px !important;
      margin-bottom: 4px !important;
    }
    &-8 {
      margin-top: 8px !important;
      margin-bottom: 8px !important;
    }
    &-12 {
      margin-top: 12px !important;
      margin-bottom: 12px !important;
    }
    &-16 {
      margin-top: 16px !important;
      margin-bottom: 16px !important;
    }
  }

  &X {
    &-4 {
      margin-left: 4px !important;
      margin-right: 4px !important;
    }
    &-8 {
      margin-left: 8px !important;
      margin-right: 8px !important;
    }
    &-12 {
      margin-left: 12px !important;
      margin-right: 12px !important;
    }
    &-16 {
      margin-left: 16px !important;
      margin-right: 16px !important;
    }
  }

  &Top {
    &-4 {
      margin-top: 4px !important;
    }
    &-8 {
      margin-top: 8px !important;
    }
    &-12 {
      margin-top: 12px !important;
    }
    &-16 {
      margin-top: 16px !important;
    }
  }

  &Bot {
    &-4 {
      margin-bottom: 4px !important;
    }
    &-8 {
      margin-bottom: 8px !important;
    }
    &-12 {
      margin-bottom: 12px !important;
    }
    &-16 {
      margin-bottom: 16px !important;
    }
    &-32 {
      margin-bottom: 32px !important;
    }
  }
}

.pad {
  &-4 {
    padding: 4px !important;
  }
  &-8 {
    padding: 8px !important;
  }
  &-12 {
    padding: 12px !important;
  }
  &-16 {
    padding: 16px !important;
  }
  &-32 {
    padding: 32px !important;
  }

  &Y {
    &-4 {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
    &-8 {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
    &-12 {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }
    &-16 {
      padding-top: 16px !important;
      padding-bottom: 16px !important;
    }
  }

  &X {
    &-4 {
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
    &-8 {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
    &-12 {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
    &-16 {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }

  &Top {
    &-4 {
      padding-top: 4px !important;
    }
    &-8 {
      padding-top: 8px !important;
    }
    &-12 {
      padding-top: 12px !important;
    }
    &-16 {
      padding-top: 16px !important;
    }
  }

  &Bot {
    &-4 {
      padding-bottom: 4px !important;
    }
    &-8 {
      padding-bottom: 8px !important;
    }
    &-12 {
      padding-bottom: 12px !important;
    }
    &-16 {
      padding-bottom: 16px !important;
    }
  }
}

.remove-link-style {
  text-decoration: none !important;
  color: #222;
  &:hover {
    text-decoration: none !important;
    color: #222;
  }
}

.orange {
  color: #f9a01b;
}

.teal {
  color: #00aaab;
}

.ff-r {
  font-family: "Roboto", sans-serif;
}

.ff-rm {
  font-family: "Roboto Medium", "Roboto", sans-serif;
}

.ff-rb {
  font-family: "Roboto Medium", "Roboto", "Roboto Black", sans-serif;
}

.inner-divider-wrapper {
  padding-left: 15px;
  padding-right: 15px;
}

.headcol {
  position: absolute;
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  width: 350px;
  // height: 58px;
  z-index: 10;
  left: 0px;
  top: auto;
}

.full-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #fff;
  color: #000;
}

.full-overlay-without-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000000;
}
