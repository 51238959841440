.MuiSelect-select.MuiSelect-select {
  padding-bottom: 0px;
  padding-top: 0px;
}

.MuiSelect-outlined.MuiSelect-outlined {
  height: 100%;
  display: flex;
  align-items: center;
}
