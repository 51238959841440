.dst-panel-container {
  align-items: flex-start;
  width: 300px;
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  flex-direction: column;
  overflow-x: hidden;

  .well-panel-accordion.chart {
    margin: 5px 10px;
  }

  .no-section-data {
    flex-direction: column;
    margin: 60px 75px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    font-weight: 400;

    > p {
      padding-top: 10px;
    }
  }

  .dst-info-header {
    padding: 2px 5px 0px 15px;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 63px;
    .dst-info-header-text {
      .dst-name {
        font-weight: 500;
        font-size: 18px;
        line-height: 47.88px;
      }

      .dst-formation {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .dst-panel-controls {
      margin-left: auto;

      .dst-panel-close-button {
        width: 28px;
        height: 28px;
        padding: 0 4px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
  .dst-info-container {
    display: grid;
    grid-template-columns: auto auto;
    margin: 30px 15px;
    padding-bottom: 10px;

    .dst-info-item {
      width: 95px;
      padding-top: 10px;
      min-height: 20px;
      width: 120px;
      word-wrap: break-word;

      &.full-width {
        grid-column: 1 / span 2;
        width: auto;
      }

      & .well-label-grouped-info {
        & .well-info-label {
          font-size: 12px;
          letter-spacing: 0.15px;
          color: #9c9c9c;
        }
        & .well-info-value {
          font-size: 14px;
          letter-spacing: 0.15px;
          padding-left: 2px;
        }
      }
    }
  }
}
