header.arlas-cs-header-section {
  background: #272727;
  height: 56px;
  padding: 8px;

  .left-header {
    margin-left: 14px;
    gap: 16px;

    .tgs-logo {
      cursor: pointer;

      > img {
        height: 26px;
      }
    }

    .MuiSvgIcon-root {
      color: #ffffffb2;
      margin-right: 14px;

      &:hover {
        cursor: pointer;
      }
    }

    .MuiDivider-root {
      margin-left: 2px;
      margin-right: 12px;
      border: 1px solid #ffffff;
      opacity: 0.1;
    }
  }

  .right-header {
    margin-left: auto;
    column-gap: 16px;
    align-items: center;
  }

  .arlas-cs-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #fff;
  }
}

.header-menu {
  .MuiPaper-root {
    margin-top: 10px;
    background: #121212;

    .MuiMenuItem-root {
      &:hover {
        background-color: rgba(2, 136, 209, 0.2);
      }
    }
  }

  &.information {
    .MuiPaper-root {
      width: 120px;
    }
  }

  &.profile-settings {
    .MuiPaper-root {
      margin-left: 10px;
    }
  }
}

// AppHeader
.user-logged-in {
  opacity: 1 !important;

  p {
    color: #adadad;
  }
}
