.search-panel-body {
  flex-grow: 1;
  flex-basis: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 5px;

  & > .numeric-date-attribute {
    border-left: 2px solid #ffffff;
  }

  button.tgsccl-button.modify-add-more-btn {
    color: rgba(0, 0, 0, 0.87);
    .modify-add-text {
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.5px;
      text-align: left;
    }
    &.disabled {
      cursor: default;
      pointer-events: none;
    }
  }
}

.search-panel-footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 50px;
  padding-top: 10px;
  margin: 6px 20px 15px 20px;
  background: #2e2e2e;

  & > * {
    margin-bottom: 16px !important;
    padding: 0px 10px 0px 8px;
    border-left: 2px;
  }
}
