.autocomplete-root {
  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 60px !important;
  }
}

.autocomplete-popper {
  &.MuiAutocomplete-popper {
    z-index: 1150;
  }
}

.autocomplete-textfield-root {
  @include qb-field-bottom-border;

  .MuiInputBase-root.MuiInput-root {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    gap: 4px;
  }

  .MuiInputBase-input {
    font-size: 14px;
  }
  .MuiCircularProgress-root {
    margin-bottom: 4px;
  }

  .MuiAutocomplete-endAdornment {
    top: calc(50% - 20px);
    .MuiIconButton-root {
      .MuiSvgIcon-root {
        color: #ffffff;
      }

      &:hover {
        background-color: unset;
      }
    }
  }

  .MuiFormLabel-root {
    top: 2px;
    left: 8px;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.15px;

    &[data-shrink="false"] {
      top: -2px !important;
      color: #ffffff;
    }
  }
  .MuiInput-root {
    margin-top: 10px;
  }
}
.autocomplete-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 0.67rem;

  .option-label {
    flex-grow: 1;
    margin-right: 4px;
  }

  .option-count {
    align-self: flex-end;
    color: rgba(255, 255, 255, 0.7);
  }
}

// override mui autocomplete popper font
.MuiAutocomplete-popper.MuiPopper-root {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiAutocomplete-paper {
    font-size: 0.67rem;
  }
}

.toggle-sort-button {
  width: 100%;
  height: 45px;
  justify-content: start !important;
  text-transform: none !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  padding-left: 15px !important;
}
