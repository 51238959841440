.ds-custom-checkbox {
  &.MuiCheckbox-root {
    padding: 7px !important;
    margin-right: 8px;
    margin-left: 6px;

    &:hover {
      background: rgba(2, 136, 209, 0.2);
    }

    & .MuiTouchRipple-root {
      display: none;
    }
  }
}
