body {
  .MuiTooltip-tooltip {
    font-size: 0.75rem;
    line-height: 1em;
    border-radius: 4px;
    background-color: rgba(97, 97, 97, 0.9);
  }
}

#tooltip.hidden {
  display: none;
}

#tooltip p {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.well-label-tooltip {
  background-color: #121212 !important;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.5rem;
  padding: 0.1rem;
  text-transform: uppercase;
  color: white !important;
  border-radius: 5px;
  animation: delay 0.9s;
  margin-top: -30px;
  margin-left: -5px;
}

@keyframes delay {
  0% {
    opacity: 0;
    display: none;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

//Custom Tooltip Info
//::after is for triangle(arrow)
//::before is for tooltip content
.tooltip-info {
  position: relative;
  float: right;
  margin-top: -1px;
}

.tooltip-info[data-tool-tip]:hover::before {
  visibility: visible;
  opacity: 1;
}

.tooltip-info[data-tool-tip]:hover::after {
  visibility: visible;
  opacity: 1;
}

.tooltip-info[data-tool-tip]::after {
  visibility: hidden;
  opacity: 0;
}

.tooltip-info[data-tool-tip]::before {
  text-align: center;
  content: attr(data-tool-tip);
  visibility: hidden;
  opacity: 0;
  min-width: 100%;
  width: max-content;
  height: 30px;
  position: absolute;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  padding: 5px 20px;
  background-color: #575757;
  color: white;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.tooltip-info[data-position="top"]::before {
  bottom: 150%;
}

.tooltip-info[data-position="top"]::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #575757 transparent transparent transparent;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tooltip-info[data-position="right"]::before {
  left: 160%;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.tooltip-info[data-position="right"]::after {
  content: "";
  position: absolute;
  top: 50%;
  //right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #575757 transparent transparent;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tooltip-info[data-position="bottom"]::before {
  top: 150%;
}

.tooltip-info[data-position="bottom"]::after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #575757 transparent;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tooltip-info[data-position="left"]::before {
  right: 150%;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.tooltip-info[data-position="left"]::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -50%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #575757;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.disabled-option {
  pointer-events: none;
  cursor: default;
  color: rgba(255, 255, 255, 0.5);
}

.disabled-option.full-width {
  width: 100%;
}

.custom-accordion-section .MuiTooltip-tooltip {
  text-align: center;
  min-width: 100%;
  width: max-content;
  height: 30px;
  margin: 0 auto;
  padding: 5px 20px;
  background-color: #575757;
  color: white;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
}
