.data-currency-container {
  button.data-currency-close-btn.tgsccl-button-circular.enabled {
    width: 28px;
    height: 28px;
    padding: 0 4px;
    background: #2e2e2e;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  button.sort-btn.tgsccl-button-circular {
    display: flex;
    background: #2e2e2e;
    visibility: hidden;
  }

  .MuiTableHead-root .MuiTableRow-root {
    .MuiTableCell-root {
      &:last-child {
        .label-with-sort {
          margin-right: 0px;
        }
      }
    }
  }

  .label-with-sort {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 15px;
    height: 28px;

    &:hover {
      button.sort-btn.tgsccl-button-circular {
        visibility: visible;
      }
    }
  }
}
