.well-panel-modal-accordion {
  &.MuiPaper-root {
    box-shadow: unset;
    background-color: #2e2e2e;
    cursor: default;
  }

  .well-panel-modal-accordion-summary {
    flex-direction: row-reverse;
    background-color: rgba(46, 46, 46, 1);
    gap: 30px;

    &.MuiAccordionSummary-root {
      margin-top: -15px;
      margin-left: -26px;
      &.MuiAccordionSummary-root:hover {
        cursor: default;
      }
      &.MuiButtonBase-root:hover {
        cursor: default;
      }

      .MuiAccordionSummary-content {
        margin-left: -13px;
      }

      .MuiAccordionSummary-expandIconWrapper {
        margin-left: 11px;
        cursor: pointer;
      }
    }
    .MuiSvgIcon-root {
      transform: rotate(270deg);
      height: 18px;
      width: 18px;
    }

    &.expanded {
      .MuiSvgIcon-root {
        transform: translate(-100%) rotate(180deg);
        height: 18px;
        width: 18px;
        transition: width 0s;
      }
    }
    .MuiCheckbox-root {
      width: 0px;
      margin: 0px 16px 0px -4px;
      pointer-events: none;
      color: #ffff;
      &.Mui-checked {
        color: #0098d9;
      }
    }
    .well-info-checkbox {
      .MuiSvgIcon-root {
        transform: rotate(0deg);
        height: 18px;
        width: 18px;
      }
      .MuiTypography-root {
        margin-left: 13px;
        &.MuiTypography-body1 {
          font-size: 16px;
        }
      }
    }
  }
  .MuiCollapse-root {
    margin-left: -30px;
    cursor: default;
  }

  .MuiAccordion-region {
    background-color: rgba(46, 46, 46, 1);
    cursor: default;
  }
  .MuiCollapse-root {
    background-color: rgba(46, 46, 46, 1);
  }

  .well-panel-modal-accordion-details {
    .MuiTypography-root {
      font-size: 16px;
    }
    &.MuiAccordionDetails-root {
      padding: 4px 8px;
      margin-left: 73px;
      margin-top: -15px;
      cursor: default;
      .section-divider {
        margin: 0 -8px;
      }
      .MuiCheckbox-root {
        color: #ffff;
        &.Mui-checked {
          color: #0098d9;
        }
      }

      .MuiFormControlLabel-root {
        width: 100%;
        margin: 0 0 0 8px;
        margin-bottom: -7px;
        width: fit-content;
        font-size: 16px;
      }
    }

    &.with-section {
      &.MuiAccordionDetails-root {
        padding: 8px 0;
        font-size: 12px;
      }

      .MuiAccordionSummary-expandIconWrapper {
        margin-right: initial;
      }
    }
  }
}

.custom-accordion-section {
  display: flex;
  flex-direction: column;

  .section-header {
    display: flex;
    align-items: center;
    padding: 0px 10px 0px 8px;

    label {
      margin: 0;
      flex-grow: 1;
      margin-left: -9px;
    }

    button {
      margin-right: -6px;
      padding: 0;
      margin-left: 5px;
    }

    .MuiIconButton-root {
      transform: rotate(0deg);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &.expanded {
        transform: rotate(0deg);
      }
      .MuiCheckbox-root {
        color: #ffff;
        &.Mui-checked {
          color: #0098d9;
        }
      }
    }

    .section-title {
      height: 43px;
      display: flex;
      align-items: center;
      flex-grow: 1;
    }
  }

  .section-content {
    padding: 10px 0px;

    .MuiFormControl-root {
      width: 100%;
    }
  }
}

.section-divider {
  &.MuiDivider-root {
    background-color: rgba(46, 46, 46, 1) !important;
  }
}
