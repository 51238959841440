.loader-section {
  margin: auto;
  /* Safari */
}

.loader-section .arlas-loader {
  border: 1rem solid #00aaab;
  border-radius: 50%;
  border-top: 1rem solid rgba(0, 170, 171, 0.2);
  width: 12rem;
  height: 12rem;
  -webkit-animation: spin 0.7s linear infinite;
  /* Safari */
  animation: spin 0.7s linear infinite;
}

.loader-section {
  margin: auto;
  .arlas-cs-loader {
    border: 1rem solid $arlas-cs-loader;
    border-radius: 50%;
    border-top: 1rem solid $arlas-cs-loader-path;
    width: 12rem;
    height: 12rem;
    -webkit-animation: spin 0.7s linear infinite; /* Safari */
    animation: spin 0.7s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
