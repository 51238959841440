.custom-cl-overriden-close-button {
  & button {
    height: 28px;
    width: 28px;
    margin-right: 8px;
    padding: 0;
    background: #272727;

    &:hover {
      background: rgba(2, 136, 209, 0.2);
    }
  }
}
