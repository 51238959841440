.polygon-search {
  box-sizing: border-box;
  background: #2e2e2e;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 4px;

  .polygon-search-content {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding-bottom: 8px;
    max-width: 320px;

    .MuiChip-label {
      text-overflow: ellipsis;
    }
  }

  .polygon-search-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 255px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .MuiTypography-root {
      color: rgba(255, 255, 255, 0.7);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
    }

    .MuiChip-root {
      size: small;
    }
  }
}

.polygon-search-header {
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 8px;

  .shapes-count {
    font-size: 14px;
    font-weight: 400;
    line-height: 20.02px;
    letter-spacing: 0.17px;
    color: rgba(255, 255, 255, 1);
  }

  .MuiSvgIcon-root {
    color: rgba(76, 167, 170, 1);
    font-size: 20px;
  }

  .MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 1px;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
  }
}

.polygon-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;

  button.tgsccl-button.tertiary.load-shape-btn {
    display: flex;
    padding: 4px 12px 4px 10px;

    text {
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.5px;
    }
  }

  .MuiDivider-root {
    margin: 0px 10px;
    border: 1px solid rgba(255, 255, 255, 0.35);
  }
}
