button.release-notes-close-btn.tgsccl-button-circular.enabled {
  width: 28px;
  height: 28px;
  padding: 0 4px;
  background: #2e2e2e;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}
