.sidebar-tabpanel {
  display: flex;
  flex-direction: column;
  padding: 16px 0px 0px 0px;
  height: 90vh;

  &.uwi {
    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
    }
  }

  .padded {
    padding: 8px 20px 0px 20px;
    margin-bottom: 16px;
  }

  .MuiDivider-root {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background-color: transparent !important;
  }

  .MuiButton-root {
    font-size: 14px;

    .MuiSvgIcon-root {
      font-size: 20px !important;
    }

    &.MuiButton-outlined:disabled {
      border: 1px solid rgba(255, 255, 255, 0.4);
    }

    &.MuiButton-contained:disabled {
      color: rgba(255, 255, 255, 0.5);
      background-color: rgba(255, 255, 255, 0.24);
    }
  }
}
