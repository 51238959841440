.analysis-list-item {
  background-color: #212121;
  justify-content: center;
  padding: 0px;
  gap: 8px;
  height: 32px;

  .MuiSvgIcon-root {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.56);
  }
  .MuiIconButton-root {
    margin-right: auto;
    flex-direction: row;
  }
}
