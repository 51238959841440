.qb-attribute {
  min-height: 48px;
  display: flex;
  align-items: end;
  margin-bottom: 16px !important;

  & > * {
    padding: 0px 20px;
    border-left: 2px;
    flex-grow: 1;
  }
}

.numeric-date-attribute {
  display: flex;
  flex-direction: column;
  gap: 16px !important;

  &.has-optype-selected {
    border-left: 2px solid #ffffff;
  }

  .select-operation-type {
    @include qb-field-bottom-border;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }

    .MuiSelect-select {
      display: flex;
      align-items: center;
      height: 36px !important;

      &:focus {
        background-color: unset;
      }
    }

    .MuiInputLabel-formControl {
      left: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.15px;

      &[data-shrink="false"] {
        top: -6px !important;
        color: #ffffff;
      }

      &[data-shrink="true"] {
        top: 2px !important;
      }
    }

    .MuiInputBase-root {
      margin-top: 8px;
      padding-left: 8px;
    }

    .MuiInputBase-input {
      font-size: 14px;
    }

    .MuiSvgIcon-root {
      color: #ffffff;
      top: calc(50% - 16px);
    }
  }

  .custom-textfield {
    @include qb-field-bottom-border;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }

    .MuiInputLabel-formControl {
      font-size: 16px !important;
      left: 8px;
      padding-bottom: 8px;
    }

    .MuiInputBase-root {
      &.MuiInput-root {
        padding-left: 8px;
      }
    }

    .MuiInputBase-input {
      font-size: 16px;
      padding-top: 0px;
    }

    .MuiIconButton-root {
      margin-bottom: 15px;
      margin-right: 0px;

      .MuiSvgIcon-root {
        fill: #9c9c9c;
      }
    }
  }

  .row-fields {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .is-between-fields {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      &.numeric {
        flex-direction: row;
        align-items: center;
      }

      .MuiTypography-root {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .helper-text {
      visibility: hidden;

      .MuiTypography-root {
        color: #f44336;
      }

      &.show {
        visibility: visible;
      }
    }
  }
}
