.arlas-cs-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 109;
  height: 23px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  background: rgba(30, 30, 30, 0.9);
  color: rgba(255, 255, 255, 0.35);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  nav {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;

        &:not(:last-of-type) {
          &::after {
            content: "|";
            padding: 0 2px;
          }
        }

        a {
          text-decoration: none;
          color: inherit;
          &:hover {
            color: #42a5f5;
          }
        }
      }
    }
  }
}
