.custom-menu-selection {
  .custom-menu-selection-item {
    align-items: flex-start;

    &.selected,
    &.selected:hover {
      background-color: rgba(25, 118, 210, 1);
    }
    &:hover {
      background-color: rgba(2, 136, 209, 0.2);
    }
    .custom-menu-selection-option-label {
      > span {
        font-size: 14px;
      }
    }

    div.custom-menu-selection-icon {
      margin-left: auto;
      min-width: unset;
      .uom-check-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
