.grid-filter-footer {
  text-align: right;
  button {
    font-size: 12px;
  }
}

.grid-filter-container {
  .filter-list-loader-container {
    position: relative;
    width: 100%;
    height: 100px;
  }

  .filter-list-loader-container .MuiCircularProgress-root {
    position: absolute;
    top: 25%;
    left: 40%;
  }

  .filter-list-loader-container .no-filter-result-text {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkbox-list-container {
    .year-filter-item {
      > .MuiIconButton-root {
        padding: 0;
        margin: 0 5px 0 12px;
      }

      > .MuiListItemButton-root {
        padding: 0;
      }

      & .nulls-checkbox {
        margin-left: 35px;
      }
    }

    .month-filter-item {
      > .MuiIconButton-root {
        padding: 0;
        margin: 0 5px 0 40px;
      }

      > .MuiListItemButton-root {
        padding: 0 16px 0 0;
      }
    }

    .day-filter-item {
      > .MuiListItemButton-root {
        padding: 0 16px 0 95px;
      }
    }

    .MuiListItemIcon-root {
      height: 40px;
      min-width: 35px;
    }

    li div:first-of-type {
      height: 33px;
    }

    .grid-filter-text-list span {
      font-size: 12px;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }

  .grid-filter-form {
    padding: 8px 15px;
    margin-top: 5px;

    &.hidden-label {
      label {
        display: none;
      }
    }

    .MuiOutlinedInput-root {
      height: 36px !important;
    }

    label {
      display: block;
      top: 0 !important;
      font-size: 1.7rem !important;
      margin-top: 7px !important;
      margin-left: 15px;
    }

    input {
      padding-top: 10.5px;
    }

    button {
      margin-top: -6px;
    }

    fieldSet legend {
      display: none;
    }
  }
}

.grid-filter-expression-builder-menu {
  height: 36px;
  border-bottom: 1px solid #ffffff4d !important;
  display: flex !important;

  .expression-filter-list {
    flex: 1;
  }

  .arrow-icon-container {
    min-width: 15px !important;

    svg {
      font-size: 1rem;
    }
  }
}

.grid-filter-number-panel-container {
  width: 280px;

  .filter-number-value-input {
    margin-bottom: 20px;

    .MuiInputBase-root {
      margin-top: 0;
    }

    .MuiFormLabel-root.Mui-focused {
      top: -12px !important;
    }

    .MuiInputLabel-formControl {
      top: -15px !important;
    }
  }
}

.grid-filter-date-field {
  margin-bottom: 20px;
}

.grid-filter-operator-field {
  margin-top: 12px !important;
  margin-bottom: 20px !important;

  svg {
    top: 0;
  }

  #filter-operator-input-field {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .filter-operator-input-label {
    top: 0 !important;
  }
}
