.asset-economics-container-accordion {
  height: auto;
  display: block;

  .cl-custom-accordion {
    background-color: transparent;
    margin-bottom: 20px;

    .section-header {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
    }

    .well-economics-analysis-view-only-container {
      grid-template-columns: auto auto;
      row-gap: 15px;
    }

    & hr {
      margin: 0 10px 0 5px;
    }
  }

  .well-economics-fields-container {
    grid-template-columns: 225px 1fr;
  }

  .well-economics-output-container {
    margin-right: 10px;
  }
}

.economics-details-footer {
  width: 100%;
  display: flex;
  justify-content: right;
  padding: 0 10px;

  & button {
    margin-top: 20px;
    margin-bottom: -12px;
    margin-left: 20px;
  }
}

.text-with-unit-container {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10%;

  & .text-unit-label {
    font-size: 12px;
    letter-spacing: 0.15px;
    color: #9c9c9c;
  }
}

.asset-economics-warning-message-container {
  height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .warning-message-item div {
    text-align: center;
  }
}

.analysis-warning-text {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.17px;
  color: #ffffffb2;
}

.economics-fullScreen-individual {
  width: 416px;
}
