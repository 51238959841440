$base-icon-url: "../images/icons/";

.map-tools-wrapper {
  position: fixed;
  right: 1.5rem;
  z-index: 3;
  bottom: 2rem;
  margin-bottom: 1rem;
  .map-action {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 100%;
    gap: 10px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      &.ma-center-action {
        & > li {
          height: 5rem;
          width: 5rem;

          span {
            display: block;
            height: inherit;
            cursor: pointer;
            position: relative;
          }
          .ma-tooltip {
            height: 5rem;
            position: absolute;
            white-space: nowrap;
            right: 108%;
            bottom: 100%;
            @include set-transform(translateX(50%));
            @include set-boxshadow(0 0.3rem 0.6rem $arlas-cs-shadow-2);
            z-index: 2;
            display: none;
            font-size: 1.6rem;
            line-height: 5rem;
            padding: 0 1rem;
            text-align: left;
            &::before {
              content: "";
              border-top-width: 1rem;
              border-top-style: solid;
              border-left-width: 1rem;
              border-left-style: solid;
              border-right-width: 1rem;
              border-right-style: solid;
              display: block;
              position: absolute;
              left: 75%;
              top: 100%;
              transform: translateX(-50%);
              z-index: 2;
            }
          }
          & > span > div {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
          }
          & > span > div:hover + .ma-tooltip {
            display: block;
          }
          &.active > span > div:hover + .ma-tooltip {
            display: none;
          }
        }
      }
      &.ma-left-action {
        display: flex;
        &.sf-scroll-map {
          position: relative;
        }
        & > li {
          height: 5rem;
          width: 5rem;
          & + li {
            border-left-width: 0.1rem;
            border-left-style: solid;
          }

          span {
            font-size: 1.8rem;
            line-height: 2.8;
            text-align: center;
            display: block;
            height: inherit;
            cursor: pointer;
            position: relative;
          }
          .ma-tooltip {
            height: 5rem;
            position: absolute;
            white-space: nowrap;
            bottom: 100%;
            right: 50%;
            transform: translateX(50%);
            @include set-boxshadow(0 0.3rem 0.6rem $arlas-cs-shadow-2);
            z-index: 2;
            display: none;
            font-size: 1.6rem;
            line-height: 5rem;
            padding: 0 1rem;
            text-align: left;
            &::before {
              content: "";
              border-top-width: 1rem;
              border-top-style: solid;
              border-left-width: 1rem;
              border-left-style: solid;
              border-right-width: 1rem;
              border-right-style: solid;
              display: block;
              position: absolute;
              left: 50%;
              top: 100%;
              transform: translateX(-50%);
              z-index: 2;
            }
          }
          & > span > div {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
          }
          & > span > div:hover + .ma-tooltip {
            display: block;
          }
          &.active > span > div:hover + .ma-tooltip {
            display: none;
          }
        }
        .ma-scale-container {
          width: auto;
          display: none;
          .ma-scale {
            display: flex;
            li {
              height: 5rem;
              width: 5rem;
              & + li {
                border-left-width: 0.1rem;
                border-left-style: solid;
              }
            }
          }
        }
      }
    }

    ul {
      &.ma-center-action {
        & > li {
          background: $arlas-cs-bg-maptool;
          &:hover {
            background: $arlas-cs-tt-primary;
          }
          span {
            &.ma-default-ico {
              background: url("/assets/images/icons/default-map.svg") center
                no-repeat;
            }
          }
          .ma-tooltip {
            background: $arlas-cs-tt-primarydark;
            color: $arlas-cs-tt-base;
            &::before {
              border-top-color: $arlas-cs-tt-primarydark;
              border-left-color: transparent;
              border-right-color: transparent;
            }
          }
        }
      }
      &.ma-left-action {
        & > li {
          a,
          a:hover,
          a:focus {
            text-decoration: none;
          }
          background: $arlas-cs-bg-maptool;
          &:hover {
            background: $arlas-cs-tt-primary;
          }
          &.ma-scale-container:hover {
            background: $arlas-cs-bg-maptool;
          }
          & + li {
            border-left-color: rgba(255, 255, 255, 0.2);
          }

          span {
            color: $arlas-cs-tt-base;
            &.ma-minus-ico {
              background: url("/assets/images/icons/zoom-out.svg") center
                no-repeat;
            }
            &.ma-plus-ico {
              background: url("/assets/images/icons/zoom-in.svg") center
                no-repeat;
            }
          }
          .ma-tooltip {
            background: $arlas-cs-tt-primarydark;
            color: $arlas-cs-tt-base;
            &::before {
              border-top-color: $arlas-cs-tt-primarydark;
              border-left-color: transparent;
              border-right-color: transparent;
            }
          }
        }
        .ma-scale-container {
          .ma-scale {
            li {
              background: transparent;
              &.selected {
                background: $arlas-cs-tt-primary;
              }
              & + li {
                border-left-color: rgba(255, 255, 255, 0.2);
              }
              &:hover {
                background: $arlas-cs-tt-primary;
              }
            }
          }
        }
      }
    }
  }
}

.default-map-view-button {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.07) 0%,
      rgba(255, 255, 255, 0.07) 100%
    ),
    #121212 !important;
  &:hover {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.21) 0%,
        rgba(255, 255, 255, 0.21) 100%
      ),
      #121212 !important;
  }
}

.zoom-map-action-buttons {
  > button {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.07) 0%,
        rgba(255, 255, 255, 0.07) 100%
      ),
      #121212;
    border: none;

    &:hover {
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.21) 0%,
          rgba(255, 255, 255, 0.21) 100%
        ),
        #121212;
    }

    &.zoom-button {
      color: #ffffff;
      border: none !important;
    }

    &.zoom-individual-button {
      border-color: #474749 !important;

      &.min-zoom-individual {
        border-left: 1px solid #474749 !important;
      }
    }
  }
}

//added !important cause tcl styling overrides scss
.map-settings-legends-container {
  & .mapOverlaysActionButton {
    background: #1e1e1e !important;

    &:hover {
      background: #434343 !important;
    }

    &.activeControl {
      background: #1976d2 !important;
    }
  }
}
