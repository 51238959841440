.map-settings-card {
  &.MuiCard-root {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 10px;
    z-index: 10;
    width: 275px;
    background-image: none;

    &.analysis-open {
      right: 484px;
    }

    .MuiCardHeader-root {
      padding: 12px 8px;

      .MuiTypography-root {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        color: rgba(255, 255, 255, 0.7);
      }

      .MuiSvgIcon-root {
        font-size: 20px !important;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0px;
    }
  }

  &.open {
    + .legends-card.open {
      right: 754px;

      &.analysis-closed-mapsettings-open {
        right: 295px;
      }

      &.analysis-open-mapsettings-open {
        right: 770px;
      }
    }
  }
}

.base-map-container {
  &.MuiFormControl-root {
    padding: 10px;
  }

  .MuiFormGroup-root {
    gap: 10px;
  }

  .layer-image {
    display: flex;
    align-items: center;

    img {
      height: 40px;
      width: 60px;
      margin-right: 10px;
    }
  }
}

.layer-color-select-container,
.well-size-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.custom-select {
  .MuiSelect-select {
    display: flex;
    align-items: center;
    height: 36px !important;
    width: 175px;

    &:focus {
      background-color: unset;
    }
  }
}

.custom-color-select-menu,
.custom-size-select-menu {
  .MuiListSubheader-root {
    background-color: unset;

    .MuiListItemButton-root {
      height: 32px;
      font-size: 13px;
      background-color: #2e2e2e;
      color: #ffffff;

      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }

      .MuiTypography-root {
        font-size: 13px;
      }

      .MuiListItemIcon-root {
        min-width: 0px;
      }
    }
  }
}

.popover-attribute-list {
  .MuiPaper-root {
    width: 221px;
    margin-top: 12px;
  }

  .MuiListItemButton-root {
    font-size: 13px;
  }

  .tree-container {
    overflow-y: auto;
    max-height: 216px;
  }
}
