.export-select-item {
  justify-content: flex-start;
  color: rgba(255, 255, 255, 0.7);

  .export-select-item-labels {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: rgba(255, 255, 255, 0.7);

    .export-select-item-divider {
      border-bottom: 1px solid #ffffff;
    }
  }
}
