.well-panel-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  .well-panel-content-container {
    display: flex;
    padding-top: 2px;
    padding-bottom: 2px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    width: 400px;

    .well-panel-buttons {
      margin-right: 3px;
      margin-top: 5px;
      .export-well-panel-button {
        &.isModalOpen {
          .MuiSvgIcon-root {
            background-color: rgba(0, 145, 234, 1);
            color: #ffffff;
          }
        }
        .MuiButton-root {
          margin-bottom: 1px;
          margin-left: -20px;
        }

        &.MuiButton-root:hover {
          background-color: rgb(0, 0, 0, 0);
          .MuiSvgIcon-root {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }

        .MuiSvgIcon-root {
          background-color: #272727;
          color: rgba(255, 255, 255, 0.7);
          border-radius: 24px;
          width: 28px;
          height: 28px;
          margin-left: 10px;
          padding: 0 4px;
          margin-top: -10px;
        }
      }
    }

    .well-panel-body {
      display: flex;
      width: 100%;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;

      .custom-tab-panel-wellInfo,
      .custom-tab-panel-plotCharts,
      .custom-tab-panel-logsAndDocs {
        flex: 1 3 200px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .custom-tab-panel-logsAndDocs {
        .logs-docs-group {
          height: 55px;
          justify-content: center;
          align-items: center;

          button {
            width: 96px;

            &.custom-disabled {
              background-color: #1e1e1e;
              color: #ffffffb2;

              &:hover {
                background: #ffffff1a;
              }
            }
          }
        }

        .log-panel-cell {
          display: flex;
          flex-direction: row;
          align-items: center;
          .well-panel-log-type {
            color: rgba(255, 255, 255, 0.7019607843);
          }

          .chip-container {
            width: 38px;
            height: 15px;
            background-color: rgba(255, 255, 255, 0.15);
            margin-left: 5px;
            align-items: end;
            &.quad > span {
              padding: 0px 5px;
            }

            .chip-label {
              font-size: 10px;
              color: rgba(255, 255, 255, 0.7);
              letter-spacing: 1px;
            }
          }
        }

        .logs-footer-container {
          height: 7%;
          display: flex;
          align-items: end;
          justify-content: center;

          button {
            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }
      .custom-tab-panel-plotCharts {
        flex: 1 3 200px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .tgsccl-accordion-section {
        background-color: unset;
        .section-content,
        .section-header:not(.expanded) {
          border-bottom: none;
        }
      }

      .well-panel-header {
        padding-left: 10px;
        padding-top: 15px;
        position: relative;
        .well-uwi {
          font-weight: 400;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.7);
        }

        .well-name-number {
          font-weight: 500;
          font-size: 18px;
          color: rgba(255, 255, 255, 1);

          &.permit-header-name-number {
            display: flex;
            align-items: baseline;

            & span {
              margin-right: 7px;
              width: 10px !important;
              height: 10px !important;
            }
          }
        }

        .well-panel-header-wrapper {
          display: flex;

          .well-panel-pill-container {
            display: flex;
            flex-direction: row;
            text-align: right;
            gap: 4px;
            margin-left: 5px;

            .well-panel-pill {
              color: #ffffff;
              font-size: 12px;
              letter-spacing: 0.4px;
              background-color: #ffffff1a;
              padding: 0 14px;
              border-radius: 15px;
            }
          }
        }

        .well-panel-header-button-container {
          position: absolute;
          right: 4px;
          top: -2px;
        }
        .well-panel-close-button {
          width: 28px;
          height: 28px;
          padding: 0 4px;
          &:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }

      .well-panel-tab {
        button {
          font-weight: 500;
          padding: 0px 0px;

          &.active {
            color: rgba(66, 165, 245, 1);
            border-bottom: 1px solid rgba(66, 165, 245, 1);
          }

          &.inactive {
            color: rgba(255, 255, 255, 1);
            border-bottom: 1px solid rgba(255, 255, 255, 1);
          }
        }
      }
    }

    .well-panel-footer {
      width: 100%;
      padding: 16px 8px;
      align-self: flex-start;
    }

    .well-panel-chart-container {
      width: 400px;
      height: 280px;

      &.loader {
        background-color: transparent;
        opacity: 0.5;
      }
    }
  }
}

.well-panel-chart-container {
  &.dst-chart {
    width: 275px;
    height: 250px;

    &.horner-plot
      .plot-chart
      .plot-container
      .svg-container
      .main-svg
      .infolayer
      .g-xtitle
      .xtitle {
      transform: translateY(-15px);
    }
  }
}
.well-info-container {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 0px 10px;
  padding-bottom: 10px;

  &.with-bottom-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .well-info-item {
    padding-top: 10px;
    min-height: 20px;
    width: 120px;
    word-wrap: break-word;

    & .well-label-grouped-info {
      & .well-info-label {
        font-size: 12px;
        letter-spacing: 0.15px;
        color: #9c9c9c;
      }
      & .well-info-value {
        font-size: 14px;
        letter-spacing: 0.15px;
        padding-left: 2px;
      }
    }
  }
}

.well-info-item-one-line {
  padding-top: 10px;
  min-height: 20px;
  width: 100%;
  word-wrap: break-word;
  margin: 0 10px;

  & .well-label-one-line-grouped-info {
    display: flex;

    & .well-info-one-line-label {
      font-size: 14px;
      letter-spacing: 0.15px;
      color: #9c9c9c;
    }
    & .well-info-one-line-value {
      font-size: 14px;
      letter-spacing: 0.15px;
      padding-left: 2px;
    }
  }
}

.accordion-section-card-container {
  & .MuiAccordionDetails-root {
    padding: 0;
  }

  & .MuiAccordionSummary-content {
    margin: 0;

    &.Mui-expanded {
      margin: 0;
    }
  }
}

.logs-and-docs-container {
  align-content: center;
  margin-top: 20px;
  margin-left: 70px;
  margin-right: 70px;

  .logs-and-docs-inner-container {
    button {
      font-weight: 500;

      &.Mui-selected {
        background-color: rgba(66, 165, 245, 1);
        color: rgba(255, 255, 255, 1);
        border-bottom: 0px;
        outline: none;
      }
    }
    .logsTab {
      border-radius: 25px 0px 0px 25px;
    }
    .docsTab {
      border-radius: 0px 25px 25px 0px;
    }
    .MuiTabs-indicator {
      background-color: transparent;
    }
  }
}

.well-panel-body {
  .perforation-section .section-content,
  .testing-section .section-content,
  .casing-section .section-content,
  .completion-section .section-content {
    margin: 0 10px;
  }
}

.well-economics-fields-container {
  display: grid;
  grid-template-columns: 190px 1fr;
  margin: 10px 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .input-col-2 {
    text-align: right;
  }

  .MuiFormControl-root {
    margin-bottom: 20px;
  }

  .highlight-changed-input fieldset {
    border: 1px solid #0091ea;
  }

  .greyed-out-input {
    label {
      color: rgba(255, 255, 255, 0.5) !important;
    }

    .MuiInputBase-root input {
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }
}

.well-economics-output-container {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100px;
  max-height: 120px;
  flex-direction: row;
  background: #31313180;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 14px 5px 5px;

  // &.disabled-container:hover {
  //   cursor: pointer;
  //   border: 1px solid #0091ea;
  // }

  &.recalculate-highlight {
    border: 1px solid #0091ea;
  }

  .InternalRateOfReturn .output-text,
  .BreakEvenPrice .output-text,
  .NetPresentValue .output-text {
    border-right: 1px solid #3e3e3e;
  }

  .output-item {
    flex: 1;
    text-align: center;

    .output-text {
      margin-top: 8px;
      padding: 0 4px;

      p {
        font-size: 15px;
        line-height: 20px;
      }

      p.econ-label {
        color: #ffffffb2;
      }

      p.econ-value {
        .loading-spinner {
          margin-top: 5px;
        }
      }
    }
  }

  .output-item.BreakEvenPrice {
    flex: 1 1 50px;
  }
}

.completion-data-container {
  overflow-y: auto;
}

.well-economics-input-buttons {
  display: flex;
  justify-content: flex-end;

  > button {
    margin: 10px 10px;

    &.tgsccl-button {
      width: auto;
      height: 30px;
    }

    > p {
      font-size: 13px;
      font-weight: bold;
    }
  }
}

.no-section-data {
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 19.92px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  text-align: center;
}

.permit-content-container {
  margin-top: 25px;
}

.section-card-container {
  margin: 5px;
  padding: 7px 0;
  width: 385px;
  min-height: 101px;
  border-radius: 4px;
  background: rgba(39, 39, 39, 1);

  & .MuiButtonBase-root {
    padding: 0;
    position: relative;

    & .MuiAccordionSummary-expandIconWrapper {
      position: absolute;
      right: 15px;
      top: 5px;
    }
  }

  & .MuiPaper-root {
    background: transparent;
    box-shadow: none;
    background-image: none;
  }
}
