.qb-editor-panel {
  height: 100%;
  z-index: 705 !important;

  & .MuiDrawer-paper {
    left: 400px;
    top: 44px;
    width: calc(100vw - 464px);
    height: calc(100% - 44px);
    position: absolute;
    background: #2e2e2e;
  }

  .qb-editor-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .qb-editor-header {
      display: flex;
      flex-direction: column;

      .qb-editor-header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0px 20px 0px 30px;

        .MuiTypography-root {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          color: #d9d9d9;
        }

        .MuiSvgIcon-root {
          color: #eeeeee;
        }
      }
    }

    .qb-editor-content {
      display: flex;
      flex-direction: row;
      height: calc(100vh - 140px);
      width: 100%;
      padding: 24px 30px;
    }
  }
}

.qb-editor-avail-container {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: auto;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 50px 48px;

  .qb-editor-avail-group {
    display: flex;
    flex-direction: column;

    .MuiTypography-root {
      font-size: 20px;
      padding: 0px 0px 26px 0px;
    }
  }
}

.qb-editor-attrib-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 250px;

  & > * {
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }
}

.qb-chip {
  &.MuiButtonBase-root.MuiChip-root {
    background-color: rgba(255, 255, 255, 0.1);
    &:hover {
      .MuiChip-deleteIcon {
        color: #ffffff;
      }
    }
  }

  .MuiChip-deleteIcon {
    color: rgba(255, 255, 255, 0.35);
  }

  .MuiChip-label {
    font-size: 12px;
  }

  &.selected {
    &.MuiChip-root {
      background-color: rgb(25, 118, 210);
    }

    &.MuiChip-label {
      font-size: 12px;
    }
  }
}
