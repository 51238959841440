.draw-controls {
  position: absolute;
  margin: 0 auto;
  width: 188px;
  z-index: 1;
  height: 30px;
  top: -100px;

  display: flex;
  align-items: center;

  border-radius: 30px;

  background-color: rgba(18, 18, 18, 1);
  transition: all 0.35s ease-in-out;

  &.enabled {
    top: 8px;
  }

  &.no-panel-open {
    right: calc(50vw - 100px);
  }

  &.app-level-open {
    right: calc((100vw - 64px) / 2 - 100px);

    &.left-panel-open {
      right: calc((100vw - 464px) / 2 - 100px);

      &.analysis-panel-open {
        left: calc((100vw - 464px) / 2 - 100px);
        right: calc((100vw - 464px) / 2 - 100px);
      }
    }

    &.analysis-panel-open {
      left: calc((100vw - 400px) / 2 - 100px);
      right: unset;
    }
  }

  &.analysis-panel-open {
    left: calc((100vw - 464px) / 2 - 100px);
  }

  & button {
    width: 50%;
    padding: 0;
    height: 100%;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }

    &:nth-child(1) {
      border-radius: 30px 0 0 30px;
      border-right: 1px solid rgba(255, 255, 255, 0.15);

      &:hover {
        border-right: 1px solid rgba(18, 18, 18, 1);
      }
    }

    &:nth-child(2) {
      border-radius: 0 30px 30px 0;
      margin-left: 0;
    }
  }

  & .MuiStack-root {
    width: 100%;
  }
}

.draw-controls-icon {
  width: 15px;
  height: 15px;
}

.draw-controls-text {
  display: flex;
  align-items: center;
  padding-left: 5px;
  color: rgba(255, 255, 255, 0.7);
}

.draw-controls-divider {
  width: 3px;
  height: 100%;
  background-color: #363537;
  margin-left: 0;
}

.draw-menu {
  .MuiListItemText-root {
    margin-right: 15px;
  }

  .MuiTypography-root {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }
}
