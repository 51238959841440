.layer-info-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 220px;
  z-index: 1;

  &.hide {
    display: none;
  }

  .tgsccl-accordion-section.layer-info-accordion {
    background: #121212;
    opacity: 0.8;

    &.hide {
      display: none;
    }

    .section-title {
      .MuiTypography-root {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.17px;
        text-align: left;
      }
    }

    .section-header {
      background-color: #1e1e1e33;
      color: #ffffff;
      height: 32px;
      border-bottom: unset;
      padding: 0 4px;
    }

    .section-content {
      background-color: #1e1e1e33;
      color: #ffffffb2;
      border-bottom: unset;
      padding: 4px 0px;

      .layer-info-sub-header {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.17px;
        text-align: left;
        margin-left: 4px;
        margin-bottom: 4px;
      }

      .layer-info-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 96px;
        margin-bottom: 15px;
      }

      .layer-info-list {
        display: flex;
        flex-direction: column;
        gap: 9px;
        margin-bottom: 15px;

        &.multi-cols {
          flex-wrap: wrap;
          max-height: 100px;
        }
      }

      .layer-info-item {
        display: flex;
        flex-direction: row;
        height: 24px;
        align-items: center;
        padding: 0px 4px;
        gap: 8px;

        .layer-info-symbol {
          display: flex;
          width: 20px;
          height: 20px;
        }

        .layer-info-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.17px;
          text-align: left;
        }

        &.carbon-storage {
          height: 12px;

          .layer-info-symbol {
            display: flex;
            width: 12.95px;
            height: 12px;
          }
        }
      }
    }
  }
}
