.button-popover-confirmation {
  .MuiPaper-root {
    display: flex;
    align-items: center;
    padding: 14px 16px;

    .MuiTypography-root {
      font-size: 14px;
    }

    .MuiButton-root {
      min-width: 0px;
      font-size: 14px;
      line-height: unset;
    }
  }
}
