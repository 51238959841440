// PlotChartArea
.plot-chart {
  display: flex;
  height: 100%;
  width: 100%;
}

.plot-chart-toolbar {
  display: flex;
  height: 28px;

  > button {
    color: #ffffff8f;
  }

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }

  .toolbar-divider {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.plot-chart-header {
  background-color: rgba(18, 18, 18, 1);
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  cursor: pointer;

  &.plot-header-for-chart {
    position: absolute;
  }

  &.full-screen-chart {
    position: relative;
  }

  .chart-header-text {
    display: flex;
    align-items: center;
    flex: 1 1 0;
    text-align: left;

    .title-divider {
      height: 19px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .MuiTypography-root {
      &:first-of-type {
        font-size: 14px;
        color: #ffffffb2;
      }

      &:last-of-type {
        font-size: 12px;
        color: #9e9e9e;
      }
    }
  }

  &.chart-full-screen {
    margin-top: 6vh;
  }

  // This fixes the additional padding of label
  // when select component is not focused
  .MuiInputLabel-formControl {
    color: #00000099 !important;
  }

  .MuiFormControl-root {
    margin: 8px;
  }

  .MuiOutlinedInput-root {
    height: 30px !important;
    width: 110px !important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000000 !important;
    border-width: 1px !important;
  }

  & > * {
    margin-left: 5px !important;
  }
}

.plot-chart-container {
  display: flex;
  height: 100%;
  width: 100%;

  &.scatter-plot-chart {
    .legendtitletext {
      transform: translateY(-5px);
    }
  }
}

.plot-chart-full-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chart-title-menu {
  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 286px;
    height: 208px;

    .chart-title-menu-item {
      .MuiListItemIcon-root,
      .MuiListItemText-primary {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 8px;
        width: 270px;
        height: 32px;
        border-radius: 4px;
      }

      .MuiTypography-root {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.7);
        width: 150px;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }

      .chart-title-list-item {
        background-color: #212121;
        justify-content: center;
        padding: 0;
        gap: 8px;
        height: 32px;

        .MuiIconButton-root {
          margin-right: auto;
          flex-direction: row;
        }

        .MuiSvgIcon-root {
          font-size: 1.5rem;
          color: rgba(255, 255, 255, 0.56);
        }
      }
    }
  }
}

.chart-config-modal {
  & .chart-cancel-button {
    border: 1px solid rgb(255 255 255 / 0%) !important;
    margin-right: 10px;

    &:hover {
      border: 1px solid rgb(255 255 255 / 0%) !important;
    }
  }

  .MuiTypography-root,
  .MuiFormLabel-root {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #fff;
  }

  .MuiDialog-container {
    .MuiPaper-root {
      background-color: #000;
      width: 670px;
      max-height: 935px;

      &.color-by-select-paper {
        background-image: linear-gradient(
          rgba(255, 255, 255, 0.12),
          rgba(255, 255, 255, 0.12)
        );
        background-color: #121212;
        width: auto;
      }

      .display-by-container {
        & .MuiGrid-item:first-child {
          padding-top: 30px;
        }

        & .MuiGrid-item:nth-child(2) {
          padding-top: 14px;
          padding-left: 40px;
        }
      }

      .MuiDialogTitle-root {
        height: 56px;
        padding: 10px 24px;

        .MuiBox-root {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .MuiButtonBase-root {
            color: #ffffff8f;
          }
        }
      }

      .MuiDialogContent-root {
        padding-top: 30px;
        border-bottom: none;
        border-top: none;

        .MuiFormControl-root {
          &.MuiTextField-root {
            &.axis-title {
              width: 200px;
            }

            &.field-range {
              width: 140px;
              margin-left: 5px;
            }

            &.color-by-select {
              width: 269px;
            }
          }

          .MuiOutlinedInput-root {
            fieldset {
              legend {
                max-width: 100%;
              }
            }
          }

          .MuiFormLabel-root {
            top: 0 !important;
          }

          #groupby-select {
            min-width: 269px;
            min-height: 40px;
          }

          #aggregate-label {
            color: #fff;
          }

          .group-wells-grid {
            align-items: center;
          }

          .display-by-label-group {
            .MuiTypography-root {
              font-size: 16px;
              width: 100%;
            }
          }
        }

        .form-divider {
          margin: 10px 0 0 10px;
          border-top: 1px dashed rgb(99, 99, 99);
        }

        .line-divider {
          margin: 10px 0 0 10px;
          border-top: 1px solid #ffffff1a;
        }

        .data-fields-wrapper {
          .MuiTableContainer-root.data-fields-table-head {
            border: 1px solid #ffffff1a;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
          }

          .MuiTableCell-root {
            border-bottom: none;
            height: 48px;
          }

          .data-fields-title {
            margin-bottom: 10px;
          }

          .MuiTableContainer-root {
            &.data-fields-table-body {
              height: 192px;
              border-bottom: 1px solid #ffffff1a;
              border-left: 1px solid #ffffff1a;
              border-right: 1px solid #ffffff1a;

              .MuiTable-root {
                tr {
                  border-bottom: 1px solid #ffffff1a;
                }

                .MuiTableRow-root {
                  .MuiTableCell-root {
                    &:not(:nth-child(1)) {
                      padding: 6px 16px;
                    }

                    &:nth-child(3) {
                      padding-left: 27px;

                      &.cell-axis-select {
                        padding-left: 20px;
                      }
                    }
                  }
                }
              }
            }

            .MuiTable-root {
              .MuiTableRow-root {
                .MuiTableCell-root {
                  &:nth-child(1) {
                    width: 10%;
                  }

                  &:nth-child(2) {
                    width: 30%;
                  }

                  &:nth-child(3) {
                    width: 60%;
                  }

                  .MuiTypography-root {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

        .x-axis-label-container {
          width: 117px;
        }

        #scale-label,
        #x-axis-data-field-label {
          color: #fff;
        }

        #scale-select-label {
          + .MuiInputBase-root {
            min-width: 200px;
            min-height: 40px;
          }
        }
      }

      .MuiDialogActions-root {
        padding: 20px 24px;
        justify-content: center;

        .MuiButtonBase-root {
          font-size: 12px;
        }
      }
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
      font-size: 0.875rem;
    }
  }

  .forecast-wrapper {
    align-items: center;
    margin: 5px 0;

    .MuiGrid-item {
      padding-top: 0;

      & label {
        font-size: 16px;
        color: #ffffff;
      }

      &:nth-child(n + 2) {
        margin-top: 15px;
      }

      .MuiTypography-root {
        font-size: 16px;
      }
    }
  }
}

.fullscreen-dialog {
  &.MuiDialog-root {
    top: 50px;

    .analysis-header-full-screen {
      position: relative;
      overflow: hidden;
    }
  }
}

.export-chart {
  display: inline;
  height: 1080px;
  width: 1920px;
  position: absolute;
  top: -1200px;

  &.gun-barrel {
    width: 665px;
    height: 450px;
    margin-left: 800px;
  }
  &.production-plot-section {
    margin-left: 800px;
  }
}

.chart-name {
  z-index: 5;
  display: flex;
  top: 1vh;
  font-size: 15px;
  margin-left: 15px;
  position: absolute;
  color: #ffffffb2;
  .chart-type {
    text-transform: uppercase;
    margin-left: 5px;
    position: relative;
    color: #ffffffb2;
  }
}

.tgs-logo-watermark {
  height: 50px;
  display: flex;
  top: 5px;
  right: 5px;
  position: absolute;
}

.tgs-logo-watermark-gun-barrel {
  height: 50px;
  display: flex;
  top: 5px;
  right: 40px;
  position: absolute;
}

.tgs-logo-watermark-production-data {
  height: 50px;
  display: flex;
  top: 5px;
  left: 1870px;
  position: absolute;
}

.react-grid-item {
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.react-grid-item.react-grid-placeholder {
  background-color: rgb(43, 163, 255);
}

.resizable {
  &.resizing {
    border: 1px solid rgba(2, 136, 209, 1);
  }

  &.dragging:hover {
    border-top: 1px solid rgba(2, 136, 209, 1);
  }
}

.react-grid-item.dragging {
  .plot-chart-header {
    cursor: url("/assets/images/resize.png"), auto;
  }
  .plot-chart-container {
    cursor: url("/assets/images/resize.png"), auto;

    .svg g.draglayer,
    svg g.draglayer > g.xy > rect {
      cursor: url("/assets/images/resize.png"), auto;
    }
  }
}

.custom-rgl-handle.react-resizable-handle {
  .rgl-handle-icon {
    width: 15px;
    height: 15px;
    color: rgb(0, 0, 0);
  }

  &.react-resizable-handle-e,
  &.react-resizable-handle-w {
    height: calc(100% - 20px);
    top: calc(100% - calc(100% - 20px));
    transform: unset;
    display: flex;
    align-items: center;
    right: -7px;

    &::after {
      border: unset;
    }
  }

  &.react-resizable-handle-s {
    width: calc(100% - 20px);
    left: calc(100% - calc(100% - 20px));
    transform: unset;
    display: flex;
    justify-content: center;
    bottom: -7px;

    &::after {
      border: unset;
    }
  }

  &.react-resizable-handle-se {
    transform: rotate(45deg);
    bottom: -4px;
    right: -8px;
    &::after {
      border: unset;
    }
  }
  &.react-resizable-handle-sw {
    transform: rotate(315deg);
    bottom: 0px;
    left: -5px;
    &::after {
      border: unset;
    }
  }
}
