$base-icon-url: "../images/icons/";

.ci {
  width: 2.4rem;
  height: 2.4rem;
  background-size: 100%;
}

.ci {
  display: inline-block;
  /* Left Navigation */
  &.ic-zoomin {
    // background: url($base-icon-url + "zoom-in.svg") no-repeat center;
  }
  &.ic-zoomout {
    // background: url($base-icon-url + "zoom-out.svg") no-repeat center;
  }
  &.ic-default-map {
    // background: url($base-icon-url+"default-map.svg") no-repeat center;
  }
}
