@import "../../partials/variables";

.dashboard-charts-panel {
  .sub-header {
    display: flex;
    align-items: center;
    height: 48px;
    padding-left: 24px;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 14px;
  }

  .dashboard-charts-container {
    display: flex;
    padding-top: 2px;
    padding-bottom: 2px;
    overflow-y: auto;
    height: 100%;
    flex-direction: column;
    align-items: center;
  }

  .dashboard-chart-main {
    width: 385px;
    //For reference to make round edges on sideways bar chart
    // &.horizontal-bar-chart .cartesianlayer .subplot .plot .barlayer .trace:first-of-type .points .point{
    //   clip-path: inset(0% 0% 0% 0% round 100px 0 0 100px)
    // }

    //override svg children of plotly stacked bar chart
    .stacked-bar-chart .zerolinelayer path {
      stroke: #9e9e9e !important;
      opacity: 0.65;
      stroke-dasharray: 3.5;
    }

    .stacked-bar-chart .infolayer .legend .scrollbox,
    .stacked-bar-chart .infolayer .legend rect {
      transform: translate(-35px, 15px);
    }

    .stacked-bar-chart .cartesianlayer .yaxislayer-above g text {
      letter-spacing: 0.4px;
    }

    .stacked-bar-chart .cartesianlayer .yaxislayer-above path.ticks {
      stroke-opacity: 0 !important;
    }

    .chart-header {
      &.stacked-bar {
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;

        .attr-list-button:hover {
          background-color: #1976d2;
        }

        .chart-title {
          padding-left: 5px;
        }
      }

      background-color: rgba(18, 18, 18, 1);
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 24px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 4px 4px 0px 0px;
    }

    .chart-container {
      width: 385px;
      height: 260px;

      &.loader {
        background-color: transparent;
      }
    }
  }
}
