.r360-modal-container {
  width: 320px;
  padding-bottom: 35px;

  .r360-modal-header {
    display: flex;
    margin-bottom: 20px;

    .modal-header {
      flex: 1;
      font-size: 16px;
      color: #ffffff;
    }

    .MuiButtonBase-root {
      padding: 3px;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .modal-content-text {
    font-size: 14px;
    color: #ffffffb2;
  }

  .MuiDialogActions-root {
    justify-content: flex-start;

    .cancel-button {
      &:hover {
        background-color: transparent;
      }
    }
  }
}
