.well-panel-accordion {
  &.loading {
    pointer-events: none;
    color: white;
  }
  .header {
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
      border-bottom: 1px solid rgba(255, 255, 255, 1);
    }

    .loading-spinner {
      width: 24px;
      height: 24px;
    }

    &.expanded {
      background-color: rgba(255, 255, 255, 0.15);
      border-bottom: 1px solid #42a5f5;
    }
  }

  .content {
    padding-left: 5px;
    padding-right: 5px;
  }

  &.chart {
    padding-top: 10px;
    .header {
      padding-left: 10px;
      padding-right: 10px;
      background-color: rgb(18, 18, 18);
      border-bottom: none;
      min-height: 35px;
      height: 35px;

      & .MuiAccordionSummary-content {
        position: relative;
      }

      & button.header-icon {
        position: absolute;
        right: 4px;
        padding: 5px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        top: -4px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.15);
        }

        &.pressed {
          background-color: #42a5f5;
        }
      }

      p {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
      }
    }
    .content {
      padding: 10px 0px;
    }

    //gun-barrel-start
    .gun-barrel-container {
      .plot-container {
        .svg-container {
          .main-svg {
            .legend {
              transform: translate(15.800000000000004px, 200px);
            }
          }
        }
      }
    }
    //gunbarrel-end
  }
}
