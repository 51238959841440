body .MuiTabs-root {
  border-bottom: none !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  height: 100%;
  align-items: center;
  display: flex;
}

.MuiButton-label {
  font-size: 14px;
}

.primary-checkout-box {
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  & .MuiSvgIcon-root {
    fill: #8b9da1 !important;
  }
  &.Mui-checked {
    & .MuiSvgIcon-root {
      fill: #f9a01b !important;
    }
  }
}

.primary-radio {
  & .MuiSvgIcon-root {
    fill: black !important;
  }
  &.Mui-checked {
    & .MuiSvgIcon-root {
      fill: #f9a01b !important;
    }
  }
}

.custom-orange-slider {
  .MuiSlider-root {
    color: #f9a01b !important;
  }
  .MuiSlider-valueLabel {
    color: rgba(0, 0, 0, 0.7) !important;
    font-size: 14px;
  }
  .MuiSlider-markLabel {
    font-size: 14px;
  }
  .MuiSlider-thumb {
    background-color: #f9a01b !important;
  }
  .MuiSlider-rail {
    background-color: #f9a01b !important;
  }
  .MuiSlider-track {
    background-color: #f9a01b !important;
  }
}

.custom-blue-slider {
  .MuiSlider-root {
    color: #7d9cb9 !important;
  }
  .MuiSlider-valueLabel {
    color: rgba(0, 0, 0, 0.7) !important;
    font-size: 14px;
  }
  .MuiSlider-markLabel {
    font-size: 14px;
  }
  .MuiSlider-thumb {
    background-color: #7d9cb9 !important;
  }
  .MuiSlider-rail {
    background-color: #7d9cb9 !important;
  }
  .MuiSlider-track {
    background-color: #7d9cb9 !important;
  }
}

.MuiSnackbarContent-root {
  min-width: 288px;
  max-width: 568px;
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
}

.export-modal {
  .title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    margin-bottom: 20px !important;
  }
  .MuiOutlinedInput-input {
    width: 533px;
    height: 39px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
  }
  .MuiInputLabel-outlined {
    font-size: 16px !important;
    background-color: white;
    padding: 0 5px;
  }
}
.MuiFormHelperText-root {
  font-size: 12px !important;
}
.export-save-btn {
  .MuiButton-label {
    color: #f9a01b !important;
  }
}

.MuiMenuItem-root {
  font-size: 13px !important;
}

.MuiDialog-container {
  background-color: #0000003b;
}

.MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
}
.MuiInputBase-root.MuiOutlinedInput-root {
  font-size: 14px !important;
}

.MuiDrawer-paper {
  position: relative !important;
}

#long-menu {
  z-index: 1302;
}
