.map-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: all 0.175s ease-in-out;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);

  &.sidebar-open {
    width: calc(100% - 256px);
  }

  &.sidebar-half-open {
    width: calc(100% - 256px);
  }
}
