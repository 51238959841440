.analysis-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 286px;
  height: 208px;
  background-color: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 100%,
      rgba(255, 255, 255, 0.08) 100%
    ),
    #121212;
}
