// Sidebar
.custom-drawer {
  margin-top: 56px;
  max-height: calc(100vh - 56px);
  margin-left: 50px;
  z-index: 1100 !important;
  width: 400px;

  &.well-panel.active-dst {
    width: 700px;

    .dst-panel-container {
      transition: width 225ms;
      background: linear-gradient(90deg, #1e1e1e 5%, rgba(30, 30, 30, 0) 70%);
    }
  }

  & .MuiFormControlLabel-label {
    font-size: 16px !important;
    color: "rgba(0, 0, 0, 0.87)";
  }

  & .MuiDrawer-paper {
    overflow: unset;
    padding: 0;
    border: 0;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.45);
    background: linear-gradient(
      90deg,
      #1e1e1e 22.69%,
      rgba(30, 30, 30, 0) 220.87%
    );
  }

  .MuiTypography-root {
    color: #ffffff;
  }
}
