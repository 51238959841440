.analyze-button-container {
  .MuiButton-root {
    width: 125px;
    height: 30px;
    background: #90caf9;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    padding: 4px, 12px, 4px, 12px;
    position: absolute;
    right: 0;
    z-index: 5;
    &:hover {
      background-color: rgb(66, 165, 245);
    }

    //Font
    .MuiTypography-root {
      font-size: 12px;
      color: rgba(0, 0, 0, 1);
      font-weight: bold;
      margin-right: -4px;
      margin-left: -1px;
    }

    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 1px;
    }
    .charts-icon-button {
      border: solid 2px #000000;
      opacity: 1;
      color: rgba(0, 0, 0, 1);
    }
  }

  .analyze-selection-container {
    margin-top: 25px;
    right: 0;
    position: absolute;
  }

  &.charts-empty {
    .MuiButton-root {
      background: #6588a4;
    }
  }
  &.charts-open {
    .MuiButton-root {
      background: #1976d2;
    }
  }
}

.analyze-chart-menu {
  .MuiList-root {
    padding-bottom: 0px;
    padding-top: 57px;
    position: relative;
  }

  .MuiPaper-root {
    overflow-y: auto;
    height: 607px;

    &.analysis-paper {
      width: 390px;
    }
  }

  .MuiButtonBase-root img {
    vertical-align: bottom;
  }

  ul {
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    height: 607px;

    & li {
      width: 195px;

      &:nth-child(-n + 4) {
        border-right: 1px solid rgba(255, 255, 255, 0.12);
      }
    }
  }

  .tooltip-info {
    position: absolute;
    right: 10px;
    top: 14px;

    &.cash-flow-chart-tooltip {
      right: 9px;
      top: 22px;
    }
  }
}

.add-new-chart {
  width: 100%;
  height: 57px;
  position: absolute;
  top: 0;

  .analyze-menu-label {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  .analyze-menu-close {
    margin-top: 5px;
    float: right;
    color: #ffffff;
    opacity: 0.5;
  }

  .MuiGrid-container {
    margin-top: 0;
    height: 57px;

    & .MuiGrid-item {
      padding-top: 0;
      position: relative;
      display: flex;
    }

    & button {
      background: none;
      position: absolute;
      top: 15px;
      right: 0;
    }
  }
}
