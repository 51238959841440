.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: rgba(30, 120, 183, 0.2) !important;
  color: rgb(30, 120, 183) !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: rgb(30, 120, 183) !important;
}

.rs-calendar-table-cell-in-range::before {
  background-color: rgba(30, 120, 183, 0.2) !important;
}

.rs-calendar-table-cell-is-today {
  border: 0px;
}

.rs-btn-link,
.rs-btn-link:hover {
  color: rgb(30, 120, 183) !important;
}

.rs-btn-primary,
.rs-btn-primary:hover {
  background-color: rgb(30, 120, 183) !important;
}

.rs-picker-toggle-active {
  box-shadow: 0px;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black !important;
}

.rs-picker-daterange-menu {
  z-index: 10001 !important;
  color: #575757 !important;
}
.rs-picker-toggle:hover {
  border-color: rgb(30, 120, 183) !important;
}
