.react-date-picker__wrapper {
  border: thin solid #00000024;
  border-radius: 5px;
  padding: 2px 8px;
}

// .react-date-picker__calendar-button.react-date-picker__button {
//   opacity: 50%;
// }

.react-calendar__month-view__days__day--weekend {
  color: rgb(0, 0, 0) !important;
}
