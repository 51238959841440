.analysis-title-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 8px;
  width: 100%;
  height: 41px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.12) 100%
    ),
    #121212;
  border-radius: 4px 4px 0px 0px;

  .chart-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 8px;
    height: 41px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.12) 0%,
        rgba(255, 255, 255, 0.12) 100%
      ),
      #121212;
    border-radius: 4px 4px 0px 0px;
    flex: none;
    align-self: stretch;
    flex-grow: 1;

    .analysis-menu-icon {
      .MuiSvgIcon-root {
        height: 12px;
        width: 12px;
      }
    }

    .MuiSvgIcon-root {
      color: rgba(255, 255, 255, 0.56);
      font-size: 5px;
    }

    .MuiDivider-root {
      height: 16px;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.3) !important;
      margin-left: calc(1% - 8px);
    }

    .wells-chart-count-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      height: 30px;

      .count-group {
        display: flex;
        align-items: flex-end;
        height: inherit;
        padding-bottom: 4px;
        gap: 5px;

        .MuiTypography-root {
          white-space: nowrap;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
          font-weight: 400;
        }

        .count-value {
          &.MuiTypography-root {
            white-space: nowrap;
            font-size: 16px;
            line-height: 24px;
            height: 22px;
            margin-right: 8px;
          }
          &.selected-analysis {
            color: #ffffff;
          }
          &.chart-count {
            color: #ffffff;
          }
        }
        .MuiDivider-root {
          height: 30px;
          width: 1px;
          background-color: rgba(255, 255, 255, 0.3) !important;
        }
      }
    }
  }

  .chart-title-menu {
    .MuiPaper-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      width: 286px;
      height: 208px;
    }
  }
  .MuiSwitch-root {
    justify-content: left;
    .Mui-checked {
      color: #90caf9;
    }
    .MuiIconButton-root {
      color: rgba(255, 255, 255, 0.56);
    }
  }

  .chart-list-menu {
    .MuiTypography-root {
      width: 75px;
      height: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
