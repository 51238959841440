$base-icon-url: "../images/icons/";
.arlas-cs-map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  &.map-cursor {
    // cursor: url($base-icon-url+'pen.svg'), auto;
    height: 1250px;
  }
  img {
    width: 100vw;
    height: 100%;
  }
}

.esriSimpleSliderIncrementButton {
  display: none;
}
.esriSimpleSliderDecrementButton {
  display: none;
}
