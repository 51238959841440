.mapsetting-label .MuiFormControlLabel-label {
  width: 100%;
  font-size: 14px;
}

.custom-accordion-section .MuiFormControlLabel-label {
  width: 100%;
  font-size: 14px;
}

.geoedgelayer-cls {
  .MuiTypography-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  &.nested-section-content .section-content {
    padding: 0 10px 10px 30px;

    & .MuiCheckbox-root {
      margin-left: 12px;
    }
  }
}

.stratigraphic-models-accordion,
.carbon-layers-accordion {
  .MuiTypography-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    width: 180px;
  }

  .section-header {
    button.expand-icon {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  &.custom-accordion-section .section-content {
    padding: 0px 10px 10px 30px;

    .MuiCheckbox-root {
      margin-left: 12px;
    }

    .nested-sub-accordion {
      .section-content {
        padding: 10px 0px 10px 20px;
      }

      .custom-select {
        .MuiSelect-select.MuiInputBase-input {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 35px;
        }
      }

      &.with-radio-group .section-content {
        padding: 0px 0px 10px 30px;

        .radio-btn-with-dropdown {
          display: flex;
          flex-direction: column;
          width: 100%;

          .custom-select {
            left: 10px;
            width: 96%;
          }
        }
      }
    }
  }
}
