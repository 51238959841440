.export-menu-dialog {
  width: 240px;
  justify-content: flex-start;
  color: rgba(255, 255, 255, 0.7);

  .export-menu-dialog-labels {
    text-transform: none;
    font-size: 0.813rem;
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin-left: 6px;
    color: rgba(255, 255, 255, 0.7);
    display: flex;
  }

  .MuiSvgIcon-root {
    font-size: 1.563rem;
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: rgba(255, 255, 255, 0.7);
  }
}
.export-menu-item-divider,
.export-select-item-divider {
  border-bottom: 1px solid #ffffff;
  margin: 0.4rem 0;
}
