body {
  margin: 0px;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #636363;
  a[aria-disabled="true"] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  h1,
  h2,
  h3 {
    font-weight: normal;
    color: #636363;
  }

  h1 {
    font-family: "DINMedium", sans-serif;
    font-size: 2.8rem;
    span {
      font-size: 2rem;
    }
  }

  h3 {
    font-family: "DINMedium", sans-serif;
    font-size: 2rem;
  }

  /* z-index 

  map and graph : none/1
  footer: 2
  maptools: 3
  crosssection: 4

  */
}
