.modularized-window-container {
  position: absolute;
  z-index: 50;
  top: 0px;
  width: 464px;
  display: none;
  right: 0px;
  height: 100%;
  overflow-x: visible;
  scrollbar-color: #121212;
  scrollbar-width: thin;
  padding-top: 5px;
  background: linear-gradient(
    270deg,
    #1e1e1e 11.12%,
    rgba(30, 30, 30, 0) 214.26%
  );
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.45);

  &.open {
    display: block;
  }

  .modules-window {
    z-index: 50;
    overflow-x: hidden;
    min-width: 20%;
    width: 100%;
    height: calc(100vh - 125px);
    position: absolute;
    top: 41px;
    margin-top: 5px;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: $arlas-cs-scrollbar-width;
    }
    &::-webkit-scrollbar-track {
      background: $arlas-cs-scrollbar-track;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $arlas-cs-scrollbar-thumb;
      background-clip: content-box;
      border: 4px solid transparent;
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: $arlas-cs-scrollbar-thumb-hover;
      background-clip: content-box;
      border: 4px solid transparent;
      border-radius: 12px;
    }
  }

  .react-grid-layout {
    position: absolute;
  }

  .reactGridItem {
    display: flex;
    width: 35%;
    height: 100%;
    transition: all 200ms ease;
    transition-property: left, top;
    text-align: center;
  }

  .deleteButton {
    position: absolute;
    top: 0%;
    right: 0;
    background: transparent;
    border: none;
    z-index: 1;
  }
}

.analysis-title-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 8px;
  width: 100%;
  height: 41px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.12) 100%
    ),
    #121212;
  border-radius: 4px 4px 0px 0px;

  .analysis-menu-container {
    position: relative;
    margin-left: 130px;
    margin-top: -35px;
  }
  .chart-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 8px;
    height: 41px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.12) 0%,
        rgba(255, 255, 255, 0.12) 100%
      ),
      #121212;
    border-radius: 4px 4px 0px 0px;
    flex: none;
    align-self: stretch;
    flex-grow: 1;

    .analysis-menu-icon {
      .MuiSvgIcon-root {
        height: 12px;
        width: 12px;
      }
    }

    .MuiSvgIcon-root {
      color: rgba(255, 255, 255, 0.56);
      font-size: 5px;
    }

    .MuiDivider-root {
      height: 16px;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.3) !important;
      margin-left: calc(1% - 8px);
      opacity: unset;
    }

    .wells-chart-count-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      height: 30px;

      .count-group {
        display: flex;
        align-items: flex-end;
        height: inherit;
        padding-bottom: 4px;
        gap: 5px;

        .MuiTypography-root {
          white-space: nowrap;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
          font-weight: 400;
        }

        .count-value {
          &.MuiTypography-root {
            white-space: nowrap;
            font-size: 16px;
            line-height: 24px;
            height: 22px;
            margin-right: 8px;
          }
          &.selected-analysis {
            color: #ffffff;
          }
          &.chart-count {
            color: #ffffff;
          }
        }
        .MuiDivider-root {
          height: 30px;
          width: 1px;
        }
      }
    }
  }

  .MuiSwitch-root {
    justify-content: left;
    .Mui-checked {
      color: #90caf9;
    }
    .MuiIconButton-root {
      color: rgba(255, 255, 255, 0.56);
    }
  }

  .chart-list-menu {
    .MuiTypography-root {
      width: 75px;
      height: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.full-screen-icon {
  border-radius: 0px 4px;
  width: 30px;
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    color: rgba(255, 255, 255, 0.56);
  }
}

.saga-modular-window {
  height: calc(100vh - 56px);
  overflow-x: hidden;
  overflow-y: hidden;
}

.react-resizable-handle {
  background-image: none;
  padding: 0;
}
