@import "./partials/variables";

main {
  position: relative;
}

* {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: $arlas-cs-scrollbar-width;
    height: $arlas-cs-scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background: $arlas-cs-scrollbar-track;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $arlas-cs-scrollbar-thumb;
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $arlas-cs-scrollbar-thumb-hover;
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-corner {
    background-color: $arlas-cs-scrollbar-track;
  }
}

.custom-primary-icon-color {
  & .MuiSvgIcon-root,
  .MuiSwitch-thumb {
    color: #42a5f5;
  }
}
