// Font Face
// =============================================================================

@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_"),
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + "." + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin set-width($width) {
  width: $width;
  min-width: $width;
  max-width: $width;
}

@mixin set-height($height) {
  height: $height;
  min-height: $height;
  max-height: $height;
}
@mixin set-boxshadow($values...) {
  -moz-box-shadow: $values;
  -webkit-box-shadow: $values;
  box-shadow: $values;
}
@mixin set-transform($values...) {
  -webkit-transform: $values;
  -moz-transform: $values;
  -o-transform: $values;
  -ms-transform: $values;
  transform: $values;
}
@mixin set-appearance($value) {
  -webkit-appearance: $value !important;
  -moz-appearance: $value !important;
  -ms-appearance: $value !important;
  -o-appearance: $value !important;
  appearance: $value !important;
}

@mixin set-transition-3($value1, $value2, $value3) {
  transition: $value1 $value2 $value3;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}

// Remove default buttons styles
@mixin rm-button-styles {
  border: none;
  background-color: unset;
  padding: 0;
}

@mixin qb-field-bottom-border {
  &:hover {
    .MuiInputBase-root.MuiInput-root {
      &::before {
        border-bottom: 1px solid rgba(255, 255, 255, 1);
      }
    }
  }

  .MuiInputBase-root.MuiInput-root {
    &::before {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }
    &::after {
      border-bottom: 1px solid rgba(66, 165, 245, 1);
    }
  }
}
