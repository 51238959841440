.floating-scrollbar-left {
  & > * {
    direction: ltr;

    &::-webkit-scrollbar {
      background-color: transparent !important;
      width: $arlas-cs-scrollbar-width !important;
    }
    &::-webkit-scrollbar-track {
      background: $arlas-cs-scrollbar-track;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $arlas-cs-scrollbar-thumb;
      background-clip: content-box;
      border: 2px solid transparent;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: $arlas-cs-scrollbar-thumb-hover;
      background-clip: content-box;
      border: 2px solid transparent;
      border-radius: 4px;
    }

    & * {
      direction: ltr;
    }
  }
}

.floating-scrollbar-right {
  & > * {
    overflow-x: hidden !important;
    overflow-y: scroll !important;

    &::-webkit-scrollbar {
      background-color: transparent !important;
      width: $arlas-cs-scrollbar-width !important;
    }
    &::-webkit-scrollbar-track {
      background: $arlas-cs-scrollbar-track;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $arlas-cs-scrollbar-thumb;
      background-clip: content-box;
      border: 2px solid transparent;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: $arlas-cs-scrollbar-thumb-hover;
      background-clip: content-box;
      border: 2px solid transparent;
      border-radius: 4px;
    }
  }
}

.custom-scrollbar-left {
  direction: rtl;
  overflow-x: hidden !important;
  overflow-y: scroll !important;

  &::-webkit-scrollbar {
    background-color: transparent !important;
    width: $arlas-cs-scrollbar-width !important;
  }
  &::-webkit-scrollbar-track {
    background: $arlas-cs-scrollbar-track;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $arlas-cs-scrollbar-thumb;
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: $arlas-cs-scrollbar-thumb-hover;
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 4px;
  }

  & * {
    direction: ltr;
  }
}

.custom-scrollbar-right {
  overflow-x: hidden !important;
  overflow-y: scroll !important;

  &::-webkit-scrollbar {
    background-color: transparent !important;
    width: $arlas-cs-scrollbar-width !important;
  }
  &::-webkit-scrollbar-track {
    background: $arlas-cs-scrollbar-track;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $arlas-cs-scrollbar-thumb;
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: $arlas-cs-scrollbar-thumb-hover;
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 4px;
  }
}
