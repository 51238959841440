.outlined-textfield {
  label {
    color: #ffffff;
    top: 1px !important;
    font-size: 15px !important;
  }

  .MuiOutlinedInput-root {
    width: 170px;
    height: 40px;
    padding-right: 4px;
  }

  .MuiInputAdornment-root p {
    font-size: 14px;
    color: #ffffff80;
  }
}
