body {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}
a,
button,
input:focus,
select:focus {
  outline: 0 !important;
}

/* Custom spacing */

/* Margin */

.ma {
  margin: auto;
}

.mt-5px {
  margin-top: 0.5rem;
}
.mt-10 {
  margin-top: 1rem;
}
.mt-15 {
  margin-top: 1.5rem;
}
.mt-20 {
  margin-top: 2rem;
}
.mt-25 {
  margin-top: 2.5rem;
}
.mt-30 {
  margin-top: 3rem;
}
.mt-40 {
  margin-top: 4rem;
}
.mt-45 {
  margin-top: 4.5rem;
}
.mt-60 {
  margin-top: 6rem;
}

.mr-5px {
  margin-right: 0.5rem;
}
.mr-15 {
  margin-right: 1.5rem;
}
.mr-20 {
  margin-right: 2rem;
}
.mr-30 {
  margin-right: 3rem;
}

.mb-5px {
  margin-bottom: 0.5rem;
}
.mb-10 {
  margin-bottom: 1rem;
}
.mb-15 {
  margin-bottom: 1.5rem;
}
.mb-20 {
  margin-bottom: 2rem;
}
.mb-30 {
  margin-bottom: 3rem;
}
.mb-35 {
  margin-bottom: 3.5rem;
}
.mb-40 {
  margin-bottom: 4rem;
}
.mb-45 {
  margin-bottom: 4.5rem;
}
.mb-80 {
  margin-bottom: 8rem;
}

.ml-5px {
  margin-left: 0.5rem;
}
.ml-20 {
  margin-left: 2rem;
}
.ml-30 {
  margin-left: 3rem;
}
