.export-card {
  .MuiButton-root {
    display: flex;
  }

  .MuiCard-root {
    position: absolute;
    display: flex;
    justify-content: center;
    left: 465px;

    z-index: 111;
    min-width: 100px;

    &:first-child {
      top: 40px;
    }

    &:nth-child(2) {
      top: 69px;
    }
  }

  .export-card-label {
    font-size: 12px;
    justify-content: center;
    margin-left: 7px;
    color: #ffffff;
    text-transform: none;
  }
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    color: #ffffff;
  }
  .export-save-search-progress {
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }
}
