.export-map-modal {
  &.shapefile {
    .MuiPaper-root {
      width: 408px;
      height: 430px;
    }
  }

  .MuiPaper-root {
    width: 355px;
    height: 410px;
    background: rgba(46, 46, 46, 1);
    box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2);

    .MuiDialogTitle-root {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #ffffff;
      padding: 14px 16px;
      height: 56px;

      .dialog-title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .MuiIconButton-root {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 4px 16px 0px 16px;

      .message-section {
        display: flex;
        gap: 7px;

        .message-text {
          font-size: 12px;
          font-weight: 400;
          line-height: 19.2px;
          letter-spacing: 0.15px;
          color: rgba(255, 255, 255, 0.7);
          padding-top: 3px;
        }
      }

      .layer-selection-section {
        .include-label.MuiTypography-root {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.15px;
        }

        .layer-list {
          display: flex;
          flex-direction: column;
          padding-left: 18px;

          .MuiFormControlLabel-root {
            height: 48px;

            .MuiTypography-root {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.15px;
            }
          }
        }
      }
    }

    .MuiDialogActions-root {
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 24px 16px;
    }
  }
}
