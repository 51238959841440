.action-control-wrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  position: absolute;
  right: 20px;
  font-size: 100%;

  .MuiSvgIcon-root {
    fill: #ffffff !important;
  }

  .zoom-button-container {
    right: 12rem !important;
    height: 30px;

    .zoom-map-action-buttons {
      height: 29px;
      border-radius: 15px;

      .zoom-button {
        background: #1e1e1e;

        &:hover {
          background: #434343;
        }

        &.zoom-left-button {
          border-bottom-left-radius: 15px;
          border-top-left-radius: 15px;
          min-width: 30px;
          padding-left: 7px;
          padding-right: 7px;

          & svg.zoom-out-icon {
            width: 20px;
            height: 20px;
          }
        }

        &.zoom-center-button {
          .MuiTypography-root {
            font-size: 16px;
            color: #ffffffb2;
          }

          &.zoom-center-menu-open {
            background: #1976d2;
          }
        }

        &.zoom-right-button {
          border-bottom-right-radius: 15px;
          border-top-right-radius: 15px;
          min-width: 30px;
          padding-left: 7px;
          padding-right: 7px;

          & svg.zoom-in-icon {
            width: 20px;
            height: 20px;
            color: #ffffffb2;
          }
        }
      }

      .zoom-individual-button {
        .MuiTypography-root {
          font-size: 16px;
          color: #ffffffb2;
        }
      }
    }
  }

  .map-tools-container {
    .map-center-action {
      &.default-view-button {
        right: 6rem !important;
        height: 34px;

        & button {
          background: #1e1e1e;
          width: 30px;
          height: 30px;

          &:hover {
            background: #434343;
          }
        }
      }
    }
  }
}

.zoom-level-menu-container {
  z-index: 10; //set to 10 to display on top of legends container

  & .MuiPaper-root {
    margin-bottom: 5px;
    height: 150px;
    overflow-y: scroll;
    width: 103px;

    & .MuiList-root {
      background-color: #2f2f2f;
    }

    & .MuiMenuItem-root {
      font-size: 16px !important;
      display: flex;
      justify-content: center;
    }

    &::-webkit-scrollbar-track {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
