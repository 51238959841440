.save-search-attrib-modal {
  .MuiDialogTitle-root {
    font-size: 20px;
    color: #ffffff;
    background-color: #393939;
  }

  .MuiDialogContent-root {
    .MuiTypography-root {
      margin-top: 16px;
    }

    .MuiTextField-root {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }
  }

  .MuiDialogActions-root {
    padding: 0 24px 20px;

    .MuiButtonBase-root {
      font-size: 12px;
    }
  }
}

.export-well-panel-modal,
.data-fields-wrapper {
  .MuiCheckbox-root,
  .MuiCheckbox-indeterminate {
    color: #0098d9 !important;

    &.Mui-checked {
      color: #0098d9;
    }
  }

  .MuiFormGroup-root {
    background-color: rgba(46, 46, 46, 1);
    margin-bottom: 10px;
    gap: 1px;
    cursor: default;
    .MuiAccordion-root {
      background-color: rgb(42, 42, 42);
    }

    .MuiFormControlLabel-root {
      width: fit-content;
      .MuiCheckbox-root {
        color: #ffff;
        &.Mui-checked {
          color: #0098d9;
        }
        .MuiTypography-root {
          &.MuiTypography-body1 {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.export-well-panel-modal {
  .MuiDialog-container {
    margin-left: 450px;
    cursor: default;
  }
  .MuiPaper-root {
    width: 580px;
    background-color: rgba(6, 6, 6);
    margin: 0;
    cursor: default;
    overflow-x: hidden;
    box-shadow: none;
  }
  .uwi-label {
    font-size: 16px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    .MuiTypography-root {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;
      color: #ffffffb2;
      margin-top: 3px;
    }
  }
  .close-button {
    position: absolute;
    .MuiIconButton-root {
      margin-left: 484px;
      margin-top: -53px;
    }
    .MuiIconButton-root:hover {
      .MuiSvgIcon-root {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 24px;
      }
    }
    .MuiSvgIcon-root {
      height: 28px;
      width: 28px;
    }
  }

  .MuiDialog-container {
    height: 100%;
    transform: translate(0, -10%);
  }
  .MuiDialog-root {
    margin-top: -200px;
    width: 580px;
    height: 561px;
    background-color: rgba(46, 46, 46, 1);
  }

  .MuiDialogTitle-root {
    font-size: 14px;
    color: #ffffff;
    background-color: rgba(46, 46, 46, 1);
  }

  .MuiSvgIcon-root {
    margin-left: 22px;
    width: 18px;
    height: 18px;
  }

  .MuiDialogContent-root {
    background-color: rgba(46, 46, 46, 1);
    width: 580px;
    height: 561px;
    .MuiTypography-root {
      margin-top: 16px;
    }

    .MuiTextField-root {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }
  }

  .MuiDialogActions-root {
    padding: 0 24px 20px;

    .MuiButtonBase-root {
      font-size: 12px;
    }
  }

  .export-button-groups {
    background-color: rgb(46, 46, 46);
    justify-content: center;
    position: relative;
    display: flex;
    width: 640px;
    gap: 8px;
    margin-top: 5px;
    margin-left: -37px;
    margin-bottom: 30px;

    .close-button {
      position: relative;
      display: flex;
      width: 66px;
      height: 30px;

      .MuiButton-root {
        font-size: 13px;
        border-radius: 24px;
        &.MuiButton-root:hover {
          border-radius: 24px;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    .round-buttons {
      display: flex;
      gap: 15px;
      .MuiButton-root {
        border-radius: 15px;
        background: rgba(25, 118, 210, 1);
        color: rgba(46, 46, 46, 1);
        font-size: 13px;
        width: 141px;
        height: 27px;
        padding: 4px, 12px, 4px, 12px;
      }
      .excel-export-button {
        .MuiButton-root {
          background-color: rgba(46, 46, 46, 1);
          color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(255, 255, 255, 0.5);
          &.MuiButton-root:hover {
            border-color: #90caf9;
            .MuiTypography-root {
              color: #90caf9;
            }
          }
        }
        .MuiTypography-root {
          font-weight: 500;
          font-size: 13px;
        }
        &.disabled-excel {
          .MuiButton-root {
            &.Mui-disabled {
              background: rgba(255, 255, 255, 0.1);
              background-color: rgba(46, 46, 46, 1);
              box-shadow: rgba(0, 0, 0, 0.25);
            }
          }
        }
      }

      .image-export-button {
        .MuiTypography-root {
          font-weight: 500;
          font-size: 13px;
          color: rgba(255, 255, 255, 1);
        }
        &.disabled-image {
          .MuiButton-root {
            &.Mui-disabled {
              background: rgba(255, 255, 255, 0.1);
              background-color: #ffffff1a;
              color: rgba(255, 255, 255, 0.2);
              box-shadow: rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
    }
  }
}
