.upload-button {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.12) 100%
    ),
    #121212;
  height: 30px;
  width: 110px !important;
  border-radius: 4px;

  .MuiTypography-root {
    color: #90caf9 !important;
  }

  .MuiSvgIcon-root {
    color: #90caf9 !important;
  }
}
