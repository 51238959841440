.sidebar-drawer-style {
  display: flex;
  flex-direction: column;
  z-index: 1200 !important;
  margin-top: 56px;
  width: 50px;

  & .MuiDrawer-paper {
    background-color: #121212;
    background-image: unset;
    overflow-y: unset;
    overflow-x: visible;

    &.default-width {
      width: 50px;
      transition: width 0.5s ease !important;
      -o-transition: width 0.5s ease;
      -ms-transition: width 0.5s ease;
      -webkit-transition: width 0.5s ease;
      -moz-transition: width 0.5s ease;
    }

    &:hover {
      width: 251px;

      & .side-bar-menu-text {
        opacity: 1;
        transition: opacity 0s;
      }

      & .well-panel-side-text {
        opacity: 1;
        transition: opacity 0s;
      }
    }

    .menu-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      padding-right: 16px;

      &:has(.Mui-disabled) {
        cursor: default;
        pointer-events: none;

        svg.accordion-icon {
          path {
            fill: #ffffff;
            fill-opacity: 0.3;
          }
        }
      }

      &:hover {
        .menu-icon-box:not(.active-menu, .Mui-disabled) {
          background: rgba(2, 136, 209, 0.2);
        }

        .side-bar-menu-text:not(.disabled-menu-text) {
          color: rgba(66, 165, 245, 1);
        }
      }
    }

    .accordion-content-container {
      padding: 8px 0px;
      background-color: #1e1e1e;
      overflow: hidden;

      .accordion-content-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 58px;
        padding-right: 8px;
        height: 32px;
        width: 250px;
        cursor: pointer;

        // Saving for Reference
        // For adding selected state
        // &.selected {
        //   background-color: rgba(25, 118, 210, 1);
        //   &:hover {
        //     background-color: rgba(25, 118, 210, 1);
        //   }
        // }

        &:hover {
          background-color: rgba(2, 136, 209, 0.2);
        }

        &.disabled {
          cursor: unset;
          pointer-events: none;
          .accordion-list-item-label {
            color: rgba(255, 255, 255, 0.3);
          }
        }

        .accordion-list-item-label {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.17px;
          color: rgba(255, 255, 255, 1);
        }

        .accordion-list-item-sublabel {
          margin-left: 13px;
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.17px;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }

    & .side-bar-menu-text {
      opacity: 0;
      transition: opacity 6s;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.5px;
      flex-grow: 1;

      white-space: nowrap;
      overflow: hidden;
      animation: 2s linear forwards;

      &.disabled-menu-text {
        color: rgba(255, 255, 255, 0.3);
      }

      &.active-menu-text {
        color: rgba(25, 118, 210, 1);
      }
    }

    .menu-icon-box {
      height: 34px;
      width: 34px;
      margin: 12px 8px !important;
      display: flex !important;
      align-items: center !important;
      background: #2e2e2e;

      &.active-menu {
        background-color: rgba(25, 118, 210, 1);
        border-radius: 50% !important;

        & svg {
          color: white;
        }

        & svg path {
          fill: white;
        }
      }

      & svg {
        width: 20px;
        height: 20px;
        color: #ffffffb2;
      }
    }
  }
}

.sidebar-menu {
  height: 100%;
  background: #272727;
  flex-grow: 1;
}

.share-active-button {
  background-color: #1976d2 !important;
}

.well-panel-menu {
  padding: 8px;

  .dashedline-container {
    margin-left: 17px;
  }

  .well-panel-bubble {
    .well-panel-uwi-bubble-container {
      position: relative;
      display: flex;
      cursor: pointer;
    }

    .panel-bubble-container {
      display: flex;
      justify-content: center;

      & button {
        margin-left: 7px;
      }
    }

    :hover {
      .well-panel-side-text {
        color: rgba(66, 165, 245, 1);
      }
    }
  }

  .well-panel-badge {
    display: flex;
    align-items: center;
    cursor: pointer;

    button {
      background-color: rgba(25, 118, 210, 1);
    }

    .well-panel-badge-text {
      margin-left: 21px;
      color: rgba(25, 118, 210, 1);

      &.collapsed {
        color: #fff;
      }
    }

    &.collapsed button {
      background: #2e2e2e;
    }
  }

  .well-panel-side-text {
    opacity: 0;
    transition: opacity 6s;
    margin-left: 27px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;

    white-space: nowrap;
    overflow: hidden;
    animation: 2s linear forwards;
  }
}

.sidenavbar-spinner {
  & svg {
    color: #90caf9;
  }
}
