.export-alert-modal {
  .MuiPaper-root {
    background-image: none;
    width: 345px;
  }

  h2 {
    color: #ffffff;
  }

  .alert-action-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
