.legends-card {
  &.MuiCard-root {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 10px;
    z-index: 10;
    width: 275px;
    background-image: none;

    &.analysis-open-mapsettings-closed {
      right: 484px;
    }

    .MuiCardHeader-root {
      padding: 12px 8px;

      .MuiTypography-root {
        font-size: 14px;
        color: #ffffffb2;
        font-weight: 500;
        letter-spacing: 1px;
      }

      .MuiSvgIcon-root {
        font-size: 20px !important;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      padding: 0px;
      height: calc(100% - 54px);
      overflow-y: auto;
    }
  }

  .legends-section-header {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0px 16px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 100%
      ),
      #121212;

    .MuiTypography-root {
      font-size: 14px;
    }
  }

  .legends-content {
    .column {
      height: calc(100% - 21px);
      width: 100%;

      & > * {
        gap: 8px;
      }

      > .MuiTypography-root {
        padding: 8px 16px;
      }

      .list {
        display: flex;
        flex-direction: column;
        padding: 8px 16px;
        height: calc(100% - 7.5px);
      }

      .legend-item {
        display: flex;
        align-items: center;
        margin: 3px 0;

        .color-swatch {
          display: flex;
        }
      }

      .well-symbol-item {
        display: flex;
        align-items: center;
        height: 20px;
      }

      .symbols-container {
        display: flex;
        align-content: center;
      }

      .symbols-label {
        margin-left: 10px;
      }
    }

    .MuiTypography-root {
      font-size: 14px;
      color: #ffffff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      letter-spacing: 0.17px;

      &.color-by-label {
        color: #ffffffb2;
      }
    }
  }
}
