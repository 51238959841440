.select-root {
  @include qb-field-bottom-border;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .MuiSelect-nativeInput {
    height: 36px;
  }

  .MuiInputBase-root {
    .MuiSelect-nativeInput {
      min-width: 60px !important;
    }

    .MuiSelect-select {
      background-color: unset;
    }

    &.MuiInput-root {
      padding-left: 8px;
    }
  }

  .MuiSvgIcon-root {
    top: calc(50% - 20px);
  }

  label.select-form-label {
    top: 10px;
    left: -7px;
    font-size: 16px;
    line-height: 16px;
  }

  .select-value-box {
    display: flex;
    padding-bottom: 8px;
    span {
      margin-left: auto;
    }
  }
}

.select-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 0.67rem;

  .option-label {
    flex-grow: 1;
    margin-right: 4px;
  }

  .option-count {
    align-self: flex-end;
    color: rgba(255, 255, 255, 0.7);
  }

  &.disabled {
    opacity: unset !important;
    font-size: 0.67rem !important;

    > span {
      color: #ffffffb2;
    }
  }
}
