.search-panel-body-uwi {
  flex: 1 3 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 10px 0px 8px;

  .upload-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin: 10px 0px;

    .chip-root {
      height: 30px;
    }
    .chip-label {
      width: 94px;
    }
  }

  .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .uwi-upload-buttons-container button h6 {
    color: #90caf9;
    font-size: 0.8rem;
  }
}

.uwi-search {
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.12) 100%
    ),
    #121212;
  height: 126px;
  border-radius: 4px;

  .uwi-location-icon {
    font-size: 20px !important;
    margin-right: 6px;
  }

  .uwi-upload-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #4ca7aa;
  }

  .savedSearch-panel-uwi {
    display: flex;
    align-items: center;
    color: #4ca7aa;
    padding: 10px;
  }

  .uwi-search-list {
    display: flex;
    align-items: center;
    .MuiBox-root {
      width: 194px;
      height: 36px;
      background-color: #121212;
      margin: auto;
      border-radius: 4px;
      border: 1px dashed rgba(255, 255, 255, 0.12);
      display: flex;
      align-items: center;
      justify-content: center;
      h6 {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .uwi-chip-container {
    margin-bottom: 12px;
    text-align: center;

    .upload-progress-container {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .upload-progress {
      font-size: 0.5rem;
    }
  }
}

.search-panel-footer-uwi {
  flex: 0 0 50px;
  padding-top: 10px;
  margin-bottom: auto;
}
