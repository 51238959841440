.saved-search-panel {
  .drawer-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: 24px;
  }

  .sub-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0px 18px;

    .MuiTypography-root {
      color: #ffffff;
      margin-bottom: 10px;
      font-size: 1rem;
    }
  }

  .saved-search-content {
    display: flex;
    flex-direction: column;

    .saved-search-list {
      overflow-x: hidden;
      padding: 0px;
    }
  }
}

.saved-search-list-item {
  .MuiListItemSecondaryAction-root {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    height: 100%;
    right: 0px;
    display: flex;
    align-items: center;

    .MuiCollapse-root {
      height: 100%;
      position: absolute;
      right: 0px;
    }

    .MuiCircularProgress-root {
      margin-right: 16px;
    }
  }

  &.not-delete-mode {
    &:hover {
      .MuiListItemSecondaryAction-root {
        opacity: 1;
      }
    }

    &:has(.share-active-button) {
      .MuiListItemSecondaryAction-root {
        opacity: 1;
      }
    }

    .MuiListItemSecondaryAction-root {
      padding: 0px 8px 0px 0px;
      opacity: 0;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .MuiIconButton-root {
      height: 41px;
      margin-right: 0px;
    }
  }

  .saved-search-confirm-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 0px;
    transition: right 500ms ease;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 100%
      ),
      #121212;

    &.hide {
      right: -160px;
    }

    .MuiDivider-root {
      margin: 0px;
      height: 50%;
    }

    .MuiButton-root {
      height: 100%;
      font-size: 14px;
    }
  }

  h5.MuiTypography-root {
    font-size: 0.938rem;
  }
  h6.MuiTypography-root {
    color: #ffffffb3 !important;
    font-size: 0.781rem;
  }
}
