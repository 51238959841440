.wrapper {
  position: absolute;
  right: 10px;
  bottom: 30px;
  z-index: 10;

  display: flex;
  flex-direction: row-reverse;
  gap: 16px;

  &.open {
    right: 484px;
  }

  &.grid-collapsed {
    bottom: 78px;
  }
}

.custom-fab {
  &.MuiFab-root {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.07) 0%,
        rgba(255, 255, 255, 0.07) 100%
      ),
      #121212;

    &:hover {
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.21) 0%,
          rgba(255, 255, 255, 0.21) 100%
        ),
        #121212;
    }

    &.selected {
      background: #9e9e9e;
    }
  }

  .MuiSvgIcon-root {
    color: #ffffff;
  }
  .MuiTypography-root {
    font-size: 13px;
    margin-left: 5px;
    padding-right: 4px;
    color: #ffffff;
  }
}
