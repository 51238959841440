.analysis-menu.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 270px;
  height: 32px;
  border-radius: 4px;
  .analysis-menu-item-labels {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    width: 150px;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
}
