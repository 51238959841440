.gun-barrel-container {
  @include rm-button-styles;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  height: 1px;
  -webkit-backface-visibility: hidden;
  g.traces text[data-unformatted="Sibling Wells"] {
    transform: translate(-2px, 0px) !important;
  }
}

.menu-well-card {
  @include rm-button-styles;
}

.input-well-card {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  display: flex !important;
}

.input-dot-style {
  padding-left: 8px;
  opacity: 0.2;
}

.well-card-content {
  width: 100%;
  padding: 0px !important;
  label {
    font-size: 0.875rem;
    line-height: 1.6rem;
  }
}

.paggination-style {
  width: 100%;
}

.tabs-well-card .MuiTabs-flexContainer button {
  width: auto;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin: 0;
  padding: 8;
}

.tabs-well-card {
  .MuiTabs-scrollButtons {
    width: 15px;
  }
  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
}

.scrollable-tabs {
  overflow-y: scroll;

  &#tabpanel-field-small {
    height: 285px;
  }

  &#tabpanel-field-medium {
    height: 385px;
  }

  &#tabpanel-field-large {
    height: 435px;
  }

  > .MuiBox-root {
    padding: 8px 16px;
  }
}
#tabpanel-table {
  > .MuiBox-root {
    padding: 0;
  }
}

.dataTableWellCard {
  // style to make table not blur on 80% zoom
  // note: change is only until 80% any lower issue persists
  * {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
  }

  .MuiDataGrid-cellContent {
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    color: #ffffffb3;
  }

  .MuiDataGrid-footerContainer {
    display: none;
  }
}

.hovertext > path {
  fill: rgba(255, 255, 255, 0.8);
}

.toogle-buttons {
  border: 0 !important;

  .small-icon {
    margin-top: 9px;
    font-size: 1rem !important;
  }

  .medium-icon {
    margin-top: 5px;
    font-size: 1.1rem !important;
  }

  .large-icon {
    font-size: 1.4rem !important;
  }
}

.custom-pagination-well-card {
  display: inline-flex;
  .right-pagination-icon
    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
    padding: 0;
  }

  .left-pagination-icon
    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
    padding: 0;
  }

  .dotPaggination {
    padding-left: 4px;
    padding-right: 4px;
  }

  .pagination-numbers {
    padding-inline: 1px;
    .MuiButtonBase-root.MuiButton-root {
      min-width: 15px;
      font-size: 10px;
      font-weight: 700;
      color: #ffffff;
      line-height: 12px;
    }

    .MuiButtonBase-root.MuiButton-root:focus {
      background: #575757;
    }

    .MuiButtonBase-root.MuiButton-root:hover {
      background: #575757;
    }

    &.active {
      background: #575757;
    }
  }
}

.flexContainer-Spinner {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}
