.well-panel-section-accordion-container {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  background-color: transparent;

  .expand-all-button {
    margin-left: auto;
    margin-bottom: 5px;
    height: 30px;
    width: 130px;
    border-radius: 15px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }

    &:active {
      background-color: rgba(2, 136, 209, 0.2);
      color: rgba(66, 165, 245, 1);
    }
  }

  .accordion-content {
    background: transparent;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 5px 0px;

    &::before {
      background-color: unset;
    }

    &.expanded {
      margin: 5px 0px;
      .accordion-summary {
        background-color: #2e2e2e;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    .accordion-summary {
      border-radius: 5px;
      background-color: #272727;
      min-height: 40px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      flex-direction: row;

      .formation {
        margin-left: auto;
      }
    }

    .accordion-details {
      background-color: #2e2e2e;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 0px 0px;

      .well-info-container {
        grid-template-columns: auto auto auto auto;

        .well-info-item {
          width: 85px;

          &.full-width {
            grid-column: 1 / span 4;
            width: auto;
          }
        }
      }
    }
  }
}
